
.post-element__arguments {
  text-align: center;

  img {
    width: auto;
    height: 65px;
    margin: auto;
    margin-bottom: 1rem;
  }

  h4 {
    color: $gray-base;
    margin: 0.5rem 0 1rem 0;
  }

  p {
    color: $medium-gray-base;
    margin-bottom: 0;
  }
}
