//
// Tooltips
// --------------------------------------------------
tooltip[tooltip-class="tooltip-button-float"]{
  position: fixed;
  right: 25px;
  bottom: 25px;
  margin-bottom: 0;
  z-index: 500;
  border-radius: 500px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.2);


  a {
    border-radius: 500px;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 400;
    font-family: $font-family-google;
    background-color: $success-base;
    border: solid 2px $success-base;
    padding: 0.1em 0.32em;

    &:hover {
      background-color: $white;
      border: solid 2px $success-base;
      color: $success-base;
    }
  }

  .tooltip-button-float {
    width: 400px;
    box-shadow: 0 10px 25px rgba(0,0,0,0.2);
    padding: 0.8rem 1rem;
    border-radius: $global-radius;
    background-color: $gray-base;

    @include breakpoint(small only) {
      width: 300px;
    }

    tip-tip {

      .close-button {
        color: $white;
        font-size: 1rem;
        margin-top: -20px;
        left: 11px;
        opacity: 0.75;

        &:hover {
          opacity: 1;
        }
      }

      .h5 {
        font-size: 0.9rem;
        color: $white;
        text-align: left;
        font-family: $font-family-second;
        margin-bottom: 5px;
      }

      p {
        font-size: 0.75rem;
        color: $light-gray;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    tip-arrow {
      border-top-color: $gray-base;
    }
  }

  &._top {

    &._left {

      .tooltip-button-float {
        left: 45px;
        top: -10px;

        tip-arrow {
          margin-left: 14px;
          border-top-color: $gray-base;

          @include breakpoint(small only) {
            margin-left: 10px;
          }
        }
      }
    }

  }
}


.offer,
.offer-details,
.home-search,
.order-panel {

  tooltip {

    tip {
      box-shadow: 0 8px 15px rgba(0,0,0,0.25);
      line-height: 1 !important;
      font-size: 0.85rem;
      background-color: $gray-base;

      tip-tip {
      }
    }
  }

  header.card-divider {
    tooltip {

      tip {

        tip-tip {
        }
      }
    }
  }

  .supplier {

    tooltip {

      tip {
        max-width: 300px !important;
        min-width: 130px !important;
        background-color: $gray-base;
        left: 38px;

        tip-tip {
          word-break: keep-all;
        }

        tip-arrow {
          border-bottom-color: $gray-base;
          border-top-color: $gray-base;
        }
      }
    }

  }
}

.order-panel {

  tooltip {

    tip {
      max-width: 300px !important;
      min-width: 175px !important;
      background-color: $gray-base;
      left: 20px !important;

      tip-tip {
        word-break: break-all;
      }

      tip-arrow {
        border-bottom-color: $gray-base !important;
        border-top-color: $gray-base !important;
      }
    }
  }
}
