//
// PageSlide
// --------------------------------------------------

.ng-pageslide {

}

.nav-slide-menu {
  background-color: $white;
  padding: 0 1rem 1rem 1rem;
  top: 52px !important;
  height: calc(100vh - 52px) !important;
  padding-bottom: 3rem;

  header {
    border-bottom: solid 1px $light-gray;
    margin-bottom: 1rem;
  }

  .menu-primary {

    li {
      padding: 1rem;
      border-bottom: solid 1px $light-gray;

      &:last-child {
        border-bottom: none;
      }

      a {
        font-family: $font-family-second;
        color: $secondary-base;
      }
    }
  }

  aside {
    padding-top: 2rem;
  }
}
