
.testimonal-item {

  @include breakpoint(small only) {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card-testimonal {
    margin-bottom: 5px;

    &:after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      left: 20px;
      bottom: -10px;
      transform: rotate(-45deg);
      border-radius: 4px;
      background-color: $white;
      z-index: 1;
    }

    .card-section {
      border-radius: $global-radius;
      position: relative;
      overflow: hidden;

      .comment {
        text-align: center;
        font-style: italic;
        font-size: 1rem;

        &:before {
          content: '" ';
        }

        &:after {
          content: ' "';
        }
      }

      .rating {
        z-index: 1;
      }

      .date {
        font-size: 0.85rem;
        color: $medium-gray-base;
      }
    }
  }

  footer {
    background-color: transparent;
    margin-top: 0.5rem;
    text-align: left;
    color: $gray-base;
    padding-left: 60px;

    .author {
      font-size: 0.85rem;
      font-style: normal;
    }
  }
}
