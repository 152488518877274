//
// Forms Validation
// --------------------------------------------------

select.ng-touched.ng-invalid,
textarea.ng-touched.ng-invalid,
input.ng-touched.ng-invalid:not(.input-qty) {
  border-color: $danger-base;
  background-color: lighten($danger-base, 46%);
}

//== UI-Select module
.ui-select-container.ng-touched.ng-invalid {
  .ui-select-toggle {
    border-color: $danger-base;
    background-color: lighten($danger-base, 46%);
  }
}

select.ng-touched.ng-valid,
textarea.ng-touched.ng-valid,
input.ng-touched.ng-valid:not(.input-qty) {
  border-color: $success-base;

  &.ng-empty {
    border-color: inherit;
  }
}

//== UI-Select module
.ui-select-container.ng-touched.ng-valid {
  .ui-select-toggle {
    border-color: $success-base;
  }

  &.ng-empty {
    .ui-select-toggle {
      border-color: inherit;
    }
  }
}


.radio,
.checkbox {
  input.ng-touched.ng-invalid-required ~ label {
    &::before {
      border-color: $danger-base;
    }
  }

  input.ng-touched.ng-valid-required ~ label {
    &::before {
      border-color: $success-base;
    }
  }
}


//== Validation Message
.input-message {
  margin-bottom: $form-spacing;

  > :first-child {
    margin: 0;
  }

  .message {
    color: $danger-base;
    @extend .help-text;
  }
}
