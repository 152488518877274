
.card-article {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  width: 100%;

  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 25%;
    height: 100%;

    @include breakpoint(small only) {
      position: relative;
      width: 100%;
      height: 150px;
      display: block;
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .card-divider {
    width: 75%;
    float: right;
    background-color: transparent;
    padding-bottom: 0.5rem;

    @include breakpoint(small only) {
      width: 100%;
      float: unset;
    }

    h4 {
      color: $gray-base;
      margin-bottom: 4px;
      transition: all linear 0.3s;

      &:hover {
        color: $success-base;
      }
    }

    .date {
      font-size: 0.85rem;
      color: $medium-gray-base;
    }

    .category {
      font-size: 0.85rem;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      border-left: solid 1px $light-gray-base-stroke;

      a {

        &:hover {
          color: $success-base;
        }
      }
    }
  }

  .card-section {
    width: 75%;
    float: right;

    @include breakpoint(small only) {
      width: 100%;
      float: unset;
    }

    a {
      color: $success-base;

      &:after {
        content: ' ...';
      }
    }
  }
}


// Pages d'articles

.post-element {

  &.post-element__articles {
    background-color: $light-gray-base-bg;

    header {
      overflow: unset;
      margin-bottom: 4rem;

      h1 {

      }

      form {

        .input-group {
          background-color: $white;
          border-radius: $global-radius;
          box-shadow: 0 5px 35px rgba(0,0,0,.25);
          margin-bottom: -1.5rem;
          position: relative;
          z-index: 10;

          .input-group-label,
          .input-group-field,
          .input-group-button {
            background-color: transparent;
            border: none;
            box-shadow: none;
            height: 100%;
          }

          .input-group-label {
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 1.1rem;
          }

          .input-group-field {
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-size: 1.1rem;
            border-radius: 0 $global-radius $global-radius 0;
          }

          .input-group-button {
            padding-right: 0.75rem;

            .button-search {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
