
.callout {
  border: none;

  &.callout-message {

    &.warning {
      color: $warning-color;

      h1 {
        color: $warning-color;
        font-family: $body-font-family;
        padding-left: 3rem;
        line-height: 1.5;

        i {
          font-size: 3rem;
          margin-top: 0.5rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &.callout-form {
    border: none;
    background-color: transparent;

    &.success {
      color: $success-base;
      border: none;
      background-color: transparent;
    }

    &.warning,
    &.alert {
      color: $warning-base;
      border: none;
      background-color: transparent;
    }
  }
}
