
header.wizard {

  nav {
    min-height: 90px;
    padding: 0.75rem 0;
    border-bottom: solid 2px $light-gray;
    margin-bottom: 1rem 0;

    ul {

      @include breakpoint(small only) {
        display: flex;
      }

      li {
        padding: 0 1.5rem;

        @include breakpoint(small only) {
          padding: 0;
          width: 33.33%;
          border-right: solid 1px $light-gray;

          &:last-child {
            border-right: none;
          }
        }

        a {
          position: relative;

          @include breakpoint(small only) {
            padding: 0;
          }

          &:after {
            content: '';
            position: absolute;
            width: 3.5rem;
            height: 2px;
            left: -2.5rem;
            top: calc(50% - 1px);
            background-color: $primary-base;

            @include breakpoint(small only) {
              display: none;
            }
          }
        }

        &:first-child {
          a {
            &:after {
              display: none;
            }
          }
        }
      }
    }

    h3 {
      text-transform: uppercase;
      line-height: 35px;
      font-size: 1rem;
      padding: 0;
      margin: 0;

      @include breakpoint(small only) {
        font-size: 0.75rem;
        word-wrap: break-word;
        text-align: center;
        text-transform: none;
      }

      .badge {
        background-color: transparent;
        border: solid 2px $primary-base;
        color: $primary-base;
        width: 45px;
        height: 45px;
        font-size: 1rem;
        margin-right: 1rem;
        vertical-align: middle;

        @include breakpoint(small only) {
          display: block;
          margin: auto;
          width: 35px;
          height: 35px;
          line-height: 1.5;
        }

        .fi-fms-doc {
          font-size: 22px;
          line-height: 32px;

          @include breakpoint(small only) {
            font-size: 18px;
            line-height: 22px;
          }
        }

        .fi-fms-check {
          font-size: 22px;
          line-height: 32px;

          @include breakpoint(small only) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }


    .is-valid {
      color: $success-base;

      a {
        &:after {
          background-color: $success-base;
        }

        h3 {
          font-family: $body-font-family;
          color: $success-base;

          .badge {
            border-color: $success-base;
            color: $success-base;
            font-family: $font-family-second;
          }
        }
      }
    }

    .is-active {
      color: $primary-base;

      a {
        background-color: transparent;

        h3 {
          .badge {
            background-color: $primary-base;
            color: $white;
          }
        }
      }
    }

    .is-invalid {
      color: $medium-gray;

      a {
        background-color: transparent;

        &:after {
          background-color: $medium-gray-base-2;
        }

        h3 {
          font-family: $body-font-family;
          color: $medium-gray-base-2;

          .badge {
            background-color: transparent;
            color: $medium-gray-base-2;
            border-color: $medium-gray-base-2;
            font-family: $font-family-second;
          }
        }
      }
    }
  }
}
