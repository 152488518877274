//
// Components
// --------------------------------------------------

//== Font Icons
$fi-fms-alert-circle-exc: "\e900";
$fi-fms-cart-simple: "\e901";
$fi-fms-check: "\e902";
$fi-fms-check-circle: "\e903";
$fi-fms-contact: "\e904";
$fi-fms-dashboard: "\e905";
$fi-fms-delivery-fast: "\e906";
$fi-fms-doc: "\e907";
$fi-fms-docs: "\e908";
$fi-fms-email: "\e909";
$fi-fms-facebook: "\e90a";
$fi-fms-google-plus: "\e90b";
$fi-fms-info: "\e90c";
$fi-fms-linkedin: "\e90d";
$fi-fms-loading: "\e90e";
$fi-fms-logout: "\e90f";
$fi-fms-pen: "\e910";
$fi-fms-pin: "\e911";
$fi-fms-position-pin: "\e912";
$fi-fms-search: "\e913";
$fi-fms-small-down: "\e914";
$fi-fms-small-left: "\e915";
$fi-fms-small-right: "\e916";
$fi-fms-small-up: "\e917";
$fi-fms-tag: "\e918";
$fi-fms-time: "\e919";
$fi-fms-twitter: "\e91a";
$fi-fms-user-circle: "\e91b";
