//
// Helper Classes
// --------------------------------------------------
.absolute {
  position: absolute;
}
.inline {
  display: inline-block;
}


//Helper Class "show-if-empty" for prevent empty column not display
.column,
.columns{
  &.show-if-empty {
    min-height: 1px;
  }
}

//Global Collapse Container
.collapse-container {
  max-height: 300px;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;
}
