
.menu-primary,
.top-bar {

  dropdown-toggle {
    font-family: $body-font-family;
    font-weight: normal;

    .dropdown-pane {
      padding: 0;
      border: none;
      box-shadow: 0 5px 25px rgba(0,0,0,0.25);
      overflow: hidden;

      .user-login {

        header {
          background-color: $white;
          padding: 1rem;
          border-bottom: solid 1px $light-gray;

          h3 {
            color: $primary-base;
            margin-bottom: 0;
          }
        }

        .tabs-panel {
          padding: 1rem;
          border-bottom: solid 1px $light-gray;

          form {
            position: relative;

            input {
              max-width: 100%;
              margin-right: 0;
              margin-bottom: 0.5rem;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .button-link {
              font-size: 0.75rem;
              color: $gray-base;
              text-decoration: underline;
              font-family: $body-font-family;
              text-transform: none;
              padding-left: 0;
              padding-right: 0;
              padding-top: 0;
            }

            .button-signin {
              margin-bottom: 0;
            }
          }
        }

        footer {
          padding: 1rem;
          background-color: $light-gray;

          .button {
            background-color: $white;
          }
        }
      }
    }

    &.logged-panel {

      .dropdown-pane {
        width: auto;
        min-width: 100px;
      }
    }
  }
}

.top-bar {

  dropdown-toggle {

    &.logged-panel {

      .dropdown-pane {

        .menu-account {

          li {

            a {


            }
          }
        }
      }
    }
  }
}
