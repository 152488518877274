//
// Lists
// --------------------------------------------------
.list {
  margin-left: 0;

  &ul {
    @extend .no-bullet;
  }

  &.inline {

    @include breakpoint(small only) {
      width: 100%;
    }

    .list-item {
      border-right: 1px solid $light-gray-base-stroke;
      display: table-cell;
      font-size: .9rem;
      padding: 0 15px;

      @include breakpoint(small only) {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 0.25rem;
        border-right: none;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .list-term {
    display: block;
    margin-bottom: 5px;
    color: $gray-base;

    @include breakpoint(small only) {
      margin-bottom: 0;
      display: inline-block;
      margin-right: 0.5rem;

      &:after {
        content: ' :';
      }
    }
  }

  .list-description {
    margin-bottom: 0;

    @include breakpoint(small only) {
      display: inline-block;
    }
  }
}
