//
// WebIcon
// --------------------------------------------------
webicon, [webicon], [data-webicon], .webicon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
}
.svg-webicon svg {
  fill: currentColor;
}
