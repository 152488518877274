

highchart {

  .highcharts-container {
    width: 100% !important;

    .highcharts-root {
      width: 100% !important;
    }
  }
}
