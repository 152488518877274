
.post-element__article {
  background-color: $light-gray-base-bg;

  header {


    img {
      filter: grayscale(100%) blur(10px);
      opacity: 0.5;
      transform: scale(1.3);
    }

    h1 {
      font-size: 2.5rem;
      text-transform: none;
      line-height: 1.2;
    }

    h2 {

      a, small {
      }

      .divider {
      }
    }
  }

  .content {
    margin-top: -55px;

    .card-single-article {
      color: $medium-gray-base;
      margin-bottom: 1rem;

      img {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: $global-radius $global-radius 0 0;
      }

      h2 {
        font-size: 1.5rem;
      }

      .card-section {
        border-radius: 0 0 $global-radius $global-radius;
      }
    }
  }
}
