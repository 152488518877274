//
// Icons
// --------------------------------------------------

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.fi-fms-alert-circle-exc {
  &:before {
    content: $fi-fms-alert-circle-exc;
  }
}
.fi-fms-cart-simple {
  &:before {
    content: $fi-fms-cart-simple;
  }
}
.fi-fms-check {
  &:before {
    content: $fi-fms-check;
  }
}
.fi-fms-check-circle {
  &:before {
    content: $fi-fms-check-circle;
  }
}
.fi-fms-contact {
  &:before {
    content: $fi-fms-contact;
  }
}
.fi-fms-dashboard {
  &:before {
    content: $fi-fms-dashboard;
  }
}
.fi-fms-delivery-fast {
  &:before {
    content: $fi-fms-delivery-fast;
  }
}
.fi-fms-doc {
  &:before {
    content: $fi-fms-doc;
  }
}
.fi-fms-docs {
  &:before {
    content: $fi-fms-docs;
  }
}
.fi-fms-email {
  &:before {
    content: $fi-fms-email;
    color: #707070;
  }
}
.fi-fms-facebook {
  &:before {
    content: $fi-fms-facebook;
  }
}
.fi-fms-google-plus {
  &:before {
    content: $fi-fms-google-plus;
  }
}
.fi-fms-info {
  &:before {
    content: $fi-fms-info;
  }
}
.fi-fms-linkedin {
  &:before {
    content: $fi-fms-linkedin;
  }
}
.fi-fms-loading {
  &:before {
    content: $fi-fms-loading;
    color: #aaa;
  }
}
.fi-fms-logout {
  &:before {
    content: $fi-fms-logout;
  }
}
.fi-fms-pen {
  &:before {
    content: $fi-fms-pen;
  }
}
.fi-fms-pin {
  &:before {
    content: $fi-fms-pin;
  }
}
.fi-fms-position-pin {
  &:before {
    content: $fi-fms-position-pin;
  }
}
.fi-fms-search {
  &:before {
    content: $fi-fms-search;
  }
}
.fi-fms-small-down {
  &:before {
    content: $fi-fms-small-down;
  }
}
.fi-fms-small-left {
  &:before {
    content: $fi-fms-small-left;
  }
}
.fi-fms-small-right {
  &:before {
    content: $fi-fms-small-right;
  }
}
.fi-fms-small-up {
  &:before {
    content: $fi-fms-small-up;
  }
}
.fi-fms-tag {
  &:before {
    content: $fi-fms-tag;
  }
}
.fi-fms-time {
  &:before {
    content: $fi-fms-time;
  }
}
.fi-fms-twitter {
  &:before {
    content: $fi-fms-twitter;
  }
}
.fi-fms-user-circle {
  &:before {
    content: $fi-fms-user-circle;
  }
}
