.top-header {
  position: relative;
  z-index: 1001;

  .top-bar {
    background-color: $white;
    box-shadow: 0 0 25px rgba(0,0,0,0.15);
    min-height: 100px;

    @include breakpoint(small only) {
      min-height: 52px;
      padding: 0;
    }

    .top-bar-left {

      @include breakpoint(small only) {
        width: 60%;
        float: left;
        min-height: 52px;
      }

      .brand img {
        width: 325px;
        height: auto;
        margin-top: 12px;
        margin-left: 10px;

        @include breakpoint(small only) {
          width: 170px;
          margin-top: 12px;
        }
      }
    }

    .top-bar-right {

      @include breakpoint(small only) {
        width: 40%;
        float: left;
        min-height: 52px;
        font-size: 0.9rem;
        padding: 0;

        .button-help,
        .button-language {
          font-size: 0.85rem;
        }

        .button-help {
          display: inline-block;
          padding: 0 10px 0 0;
        }

        .language-selector {
          display: inline-block;
          padding: 15px 10px 0 0;
        }
      }

      .language-selector {

        .dropdown-pane {
          width: auto;
          padding: 0.35rem;
          box-shadow: 0 3px 15px rgba(0,0,0,0.25);
          border: none;
        }
      }

      .menu-primary {

      }
    }
  }
}

@media screen and #{breakpoint(small only)} {
  .top-header {
    .top-bar-right {
      > .menu {
        display: flex;
        min-height: 52px;

        > li {
          border-left: solid 1px $light-gray;
        }

        .button-user {
          border-radius: 0;
          height: 100%;
          color: $gray-base;

          .fi {
            font-size: 1.5rem !important;
            margin-top: 4px;
            display: block;
          }
        }

        .hamburger-header {
          font-size: 0;
          color: transparent;
          border-radius: 0;
          margin-top: 5px;
        }
      }
    }
  }
}
