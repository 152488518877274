
.article-archive {
  display: flex;
  flex-wrap: wrap;

  article {
    margin-bottom: 1.5rem;

    @include breakpoint(small only) {
      margin-bottom: 2rem;
    }

    .card {
      margin-bottom: 0;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;

      .card-section.sumary {
        flex: 1 0 auto;
        border-bottom: none;
      }
    }
  }
}
