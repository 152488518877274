@charset "UTF-8";

.checkbox {
  padding-left: 20px; }
  .checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    height: 23px; }
    .checkbox label::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 21px;
      height: 21px;
      left: 0;
      margin-left: -28px;
      border: 1px solid #cacaca;
      border-radius: 0;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .checkbox label::after {
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      top: -2px;
      margin-left: -27px;
      padding-left: 3px;
      font-size: 0.875rem;
      color: #0a0a0a; }
  .checkbox input[type="checkbox"],
  .checkbox input[type="radio"] {
    position: absolute;
    margin: 4px 4px 4px -18px;
    opacity: 0;
    z-index: 1;
    width: 18px;
    height: 17px; }
    .checkbox input[type="checkbox"]:focus + label::before,
    .checkbox input[type="radio"]:focus + label::before {
      outline: 0; }
    .checkbox input[type="checkbox"]:checked + label::after,
    .checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: ""; }
    .checkbox input[type="checkbox"]:indeterminate + label::after,
    .checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 0;
      margin-left: -22.5px;
      margin-top: 11px; }
    .checkbox input[type="checkbox"]:disabled + label,
    .checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .checkbox input[type="checkbox"]:disabled + label::before,
      .checkbox input[type="radio"]:disabled + label::before {
        background-color: #e6e6e6;
        cursor: not-allowed; }
  .checkbox.checkbox-circle label::before {
    border-radius: 50%; }
  .checkbox.checkbox-inline {
    margin-top: 0;
    display: inline-block; }

.checkbox.primary input[type="checkbox"]:checked + label::before,
.checkbox.primary input[type="radio"]:checked + label::before {
  background-color: #1779ba;
  border-color: #1779ba; }

.checkbox.primary input[type="checkbox"]:checked + label::after,
.checkbox.primary input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox.secondary input[type="checkbox"]:checked + label::before,
.checkbox.secondary input[type="radio"]:checked + label::before {
  background-color: #767676;
  border-color: #767676; }

.checkbox.secondary input[type="checkbox"]:checked + label::after,
.checkbox.secondary input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox.alert input[type="checkbox"]:checked + label::before,
.checkbox.alert input[type="radio"]:checked + label::before {
  background-color: #cc4b37;
  border-color: #cc4b37; }

.checkbox.alert input[type="checkbox"]:checked + label::after,
.checkbox.alert input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox.warning input[type="checkbox"]:checked + label::before,
.checkbox.warning input[type="radio"]:checked + label::before {
  background-color: #ffae00;
  border-color: #ffae00; }

.checkbox.warning input[type="checkbox"]:checked + label::after,
.checkbox.warning input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox.success input[type="checkbox"]:checked + label::before,
.checkbox.success input[type="radio"]:checked + label::before {
  background-color: #3adb76;
  border-color: #3adb76; }

.checkbox.success input[type="checkbox"]:checked + label::after,
.checkbox.success input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox.primary input[type="checkbox"]:indeterminate + label::before,
.checkbox.primary input[type="radio"]:indeterminate + label::before {
  background-color: #1779ba;
  border-color: #1779ba; }

.checkbox.primary input[type="checkbox"]:indeterminate + label::after,
.checkbox.primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox.secondary input[type="checkbox"]:indeterminate + label::before,
.checkbox.secondary input[type="radio"]:indeterminate + label::before {
  background-color: #767676;
  border-color: #767676; }

.checkbox.secondary input[type="checkbox"]:indeterminate + label::after,
.checkbox.secondary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox.alert input[type="checkbox"]:indeterminate + label::before,
.checkbox.alert input[type="radio"]:indeterminate + label::before {
  background-color: #cc4b37;
  border-color: #cc4b37; }

.checkbox.alert input[type="checkbox"]:indeterminate + label::after,
.checkbox.alert input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox.warning input[type="checkbox"]:indeterminate + label::before,
.checkbox.warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffae00;
  border-color: #ffae00; }

.checkbox.warning input[type="checkbox"]:indeterminate + label::after,
.checkbox.warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox.success input[type="checkbox"]:indeterminate + label::before,
.checkbox.success input[type="radio"]:indeterminate + label::before {
  background-color: #3adb76;
  border-color: #3adb76; }

.checkbox.success input[type="checkbox"]:indeterminate + label::after,
.checkbox.success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.radio {
  padding-left: 20px; }
  .radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    height: 23px; }
    .radio label::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 21px;
      height: 21px;
      left: 0;
      margin-left: -28px;
      border: 1px solid #cacaca;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .radio label::after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 13px;
      height: 13px;
      left: 4px;
      top: 4px;
      margin-left: -28px;
      border-radius: 50%;
      background-color: #0a0a0a;
      -webkit-transform: scale(0, 0);
           -o-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .radio input[type="radio"] {
    position: absolute;
    margin: 4px 4px 4px -18px;
    opacity: 0;
    z-index: 1;
    width: 18px;
    height: 17px; }
    .radio input[type="radio"]:focus + label::before {
      outline: 0; }
    .radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
           -o-transform: scale(1, 1);
              transform: scale(1, 1); }
    .radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
  .radio.radio-inline {
    margin-top: 0;
    display: inline-block; }

.radio.primary input[type="radio"] + label::after {
  background-color: #1779ba; }

.radio.primary input[type="radio"]:checked + label::before {
  border-color: #1779ba; }

.radio.primary input[type="radio"]:checked + label::after {
  background-color: #1779ba; }

.radio.secondary input[type="radio"] + label::after {
  background-color: #767676; }

.radio.secondary input[type="radio"]:checked + label::before {
  border-color: #767676; }

.radio.secondary input[type="radio"]:checked + label::after {
  background-color: #767676; }

.radio.alert input[type="radio"] + label::after {
  background-color: #cc4b37; }

.radio.alert input[type="radio"]:checked + label::before {
  border-color: #cc4b37; }

.radio.alert input[type="radio"]:checked + label::after {
  background-color: #cc4b37; }

.radio.warning input[type="radio"] + label::after {
  background-color: #ffae00; }

.radio.warning input[type="radio"]:checked + label::before {
  border-color: #ffae00; }

.radio.warning input[type="radio"]:checked + label::after {
  background-color: #ffae00; }

.radio.success input[type="radio"] + label::after {
  background-color: #3adb76; }

.radio.success input[type="radio"]:checked + label::before {
  border-color: #3adb76; }

.radio.success input[type="radio"]:checked + label::after {
  background-color: #3adb76; }

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: "FontAwesome";
  content: ""; }

input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff; }

input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff; }

/*# sourceMappingURL=awesome-foundation6-checkbox.css.map */
