
.dash-divider {
  position: relative;
  text-align: center;
  margin: 0.5rem 0;
  float: left;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $light-gray;
    top: 50%;
    left: 0;
  }

  span {
    position: relative;
    margin: auto;
    background: $white;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    font-family: $body-font-family;
    color: $medium-gray;
  }
}


// Social login
.social-login {
  // padding: 0;
  // margin: 0;

  .columns {
    &:first-child {
      padding-left: 0;
      padding-right: 0.5rem;
    }

    &:last-child {
      padding-right: 0;
      padding-left: 0.5rem;
    }
  }

  .button-user-facebook,
  .button-user-google {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}

// Rating
.rating {
  float: right;

  .rating-link {
    margin-left: 0.35rem;
    font-size: 0.75rem;
    line-height: 22px;
  }

  .ngrateit {

    .ngrateit-star {
      background-size: 10px;

      &:nth-child(2n) {
        margin-right: 3px;
      }
    }
  }
}
