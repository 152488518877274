//
// Menus
// --------------------------------------------------

.menu-primary {
  margin-top: 0.75rem;
  margin-bottom: 0.35rem;

  > li {
    float: left;

    > a {
      float: left;
      color: $secondary-base;
      font-family: $font-family-second;
      text-transform: none;
      position: relative;
      transition: all linear 0.3s;
      padding-left: 0;
      padding-right: 0;
      margin: 0 1rem;
      height: 39px;
      line-height: 1.2;

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background-color: $primary-base;
        bottom: 0;
        left: 50%;
        transition: all linear 0.3s;
        transform: translateX(-50%);
      }

      &:hover {
        color: $primary-base;

        &:after {
          width: 100%;
        }
      }
    }

    &.is-active {

      a {
        background-color: transparent;
        color: $primary-base !important;

        &:after {
          width: 100%;
        }
      }
    }
  }

  .button-group {
    margin-bottom: 0;
    margin-left: 1rem;

    .button-account {
      float: left;
      height: 39px;
      font-family: $font-family-second;
      text-transform: none;
    }

    .button-cart {
      border-radius: 0 $global-radius $global-radius 0;
      background-color: $white;
      left: -8px;
      float: left;
      height: 39px;

      &:hover {

        &:after {
          display: none;
        }
      }
    }
  }

  &.vertical {
    @extend .accordion-menu;

    .button-cart {
      display: inline-block;
    }
  }
}

// Footer menu
footer {

  .footer-main {

    .menu-primary {
      margin-top: 0;
      margin-bottom: 0;

      > li {

        > a {
          color: $white;

          &:hover {
            color: $white;

            &:after {
              background-color: $white !important;
            }
          }
        }

        &.is-active {

          a {
            color: $white !important;

            &:after {
              background-color: $white !important;
            }
          }
        }
      }
    }
  }
}




//
.menu-account {
  @extend .vertical;
}

.menu-sidebar-account {
  @extend .vertical;
}

.menu-language {
 @extend .vertical;
 @extend .align-center;

  li {
    a {
      font-family: $body-font-family;
      color: $medium-gray;
      font-size: 0.75rem;
      padding: 0.35rem 0.35rem;
    }
  }
}

.menu-order {
 @extend .align-center;
}


// Account Menu
dropdown-toggle {

  .dropdown-pane {

    .menu-account {
      width: auto;
      white-space: nowrap;

      li {
        border-bottom: solid 1px $light-gray;

        &:last-child {
          border-bottom: none;
        }

        a {
          color: $gray-base;
          font-family: $body-font-family !important;
          font-weight: normal !important;
          text-transform: none !important;
          font-size: 0.9rem !important;

          &:hover {
            background-color: $light-gray;
          }

          i {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .logged-panel {

    .dropdown-pane {
      width: auto;
    }
  }
}


// Account sidebar
.account {

  .sidebar {
    padding-top: 0;
    width: 265px !important;

    .menu-sidebar-account {

      li {
        border-bottom: solid 1px $light-gray-base-stroke;

        &.is-active {

          a {
            color: $success-base;
            border-left: solid 3px $success-base;
            background-color: transparent;
          }
        }

        a {
          display: block;
          color: $secondary-base;
          padding: 1.3rem 1rem;
          text-transform: uppercase;
          font-family: $font-family-second;
          border-left: solid 0 transparent;
          width: 100%;
          transition: all linear 0.3s;

          &:hover {
            border-left: solid 3px $success-base;
            color: $success-base;
          }

          i {
            margin-right: 1rem;
          }
        }
      }
    }

    .button-logout {
      margin-top: 2rem;

      i {
        margin-right: 0.75rem;
      }
    }
  }

  .main {
    width: calc(100% - 265px) !important;

    @include breakpoint(small only) {
      width: 100% !important;
      background-color: $white;
      padding-bottom: 0;
    }

    .ng-leave {
      display: none !important;
    }
  }
}
