//
// Sidebars
// --------------------------------------------------
@include breakpoint(medium) {
  .main{
    .animate & {
      transition: width 0.5s;
    }
  }

  .sidebar {
    .animate & {
      transition: width 0.5s, margin 0.5s;
    }
    margin-left: - percentage(($sidebar-columns) / $main-columns) ;

    @include breakpoint(xxlarge) {
      margin-left: - percentage(($sidebar-columns - 1) / $main-columns) ;
    }
  }

  .sidebar-active .sidebar {
    margin-left: 0;
  }
}



.sidebar {
  background-color: $white;
  padding: 1rem 0;
  position: relative;

  @include breakpoint(small only) {
    padding: 0;
  }

  .button-close-sidebar {
    position: absolute;
    right: -30px;
    top: 1.5rem;
    background-color: lighten($light-gray, 3.5%);
    border-top: solid 1px lighten($medium-gray, 40%);
    border-right: solid 1px lighten($medium-gray, 40%);
    border-bottom: solid 1px lighten($medium-gray, 40%);
    border-radius: 0 $global-radius $global-radius 0;
    color: $medium-gray;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    z-index: 100;

    @include breakpoint(small only) {
      display: none;
    }
  }

  .sidebar-header {
    padding-bottom: 1rem;
    padding-top: 0.8rem;
    border-bottom: solid 1px lighten($medium-gray, 30%);
    margin-bottom: 1rem;
    width: 100%;
    display: flex;

    h3 {
      font-size: 1.2rem;
      color: $gray-base;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0;
      width: 100%;
      align-self: center;
    }
  }

  .sidebar-content {
    padding: 1px 1rem;

    .button-search {
      background-color: $gray-base;
    }

  }
}
