//
// Global
// --------------------------------------------------

body {
  color: $gray-base;
  background-color: darken($primary-base, 5%);
}

a, button {
  outline: none;
  transition: all linear 0.3s;
}

p:empty {
  display: none;
}

p {
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  color: $primary-base;
  font-family: $font-family-second;
  font-weight: normal;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $primary-base;
  font-family: $font-family-second;
  font-weight: normal;
  line-height: 1.2;

  &.secondary {
    color: $secondary-base;
  }
}

main#main {
  min-height: 70vh;
  background-color: $light-gray-base-bg;

  @include breakpoint(small only) {
    min-height: 50vh;
    height: auto;
    position: relative;
    overflow: hidden;
  }

  > div {
    min-height: 70vh;

    @include breakpoint(small only) {
      min-height: 50vh;
      height: auto;
      position: relative;
      overflow: hidden;
    }
  }
}
