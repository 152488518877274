.post-element {
  padding-bottom: 4rem;
  padding-top: 5rem;

  header {
    position: relative;
    margin-bottom: 3rem;

    h2 {
      position: relative;
      font-size: 1.8rem;
      font-weight: 700;
      z-index: 5;
    }

    h3 {

      &.subheader {
        position: absolute;
        font-weight: 700;
        font-size: 4.5rem;
        text-transform: uppercase;
        left: 50%;
        bottom: -6px;
        transform: translateX(-50%);
        line-height: 1;
        margin: 0;
        z-index: 0;
      }
    }
  }
}

.post-element__article,
.post-element__articles,
.page {
  padding-top: 0;
  position: relative;
  overflow: hidden;

  header {
    background-color: $primary-base;
    color: $white;
    margin-bottom: 0;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      z-index: 0;
    }

    h1 {
      padding: 4rem 1rem 3.75rem 1rem;
      color: $white;
      margin-bottom: 0;
      z-index: 1;
      position: relative;
    }

    h2 {
      color: $white;
      font-family: $body-font-family;
      font-weight: 400;
      margin-top: -40px;
      margin-bottom: 100px;
      font-size: 1.2rem;
      z-index: 2;
      position: relative;

      a, small {
        color: $white;
        opacity: 0.75;
      }

      .divider {
        margin: 0 0.75rem;
      }
    }
  }
}

.page {

  header {
    padding-bottom: 3rem;


  }

  .post-elements {
    margin-top: -3rem;
    padding-bottom: 3rem;
    overflow: hidden;

    .post-element {
      padding-bottom: 0;
      padding-top: 0;
      border-radius: 0;

      &:first-child {
        padding-top: 0;

        .row {
          border-radius: $global-radius $global-radius 0 0;
        }
      }

      &:last-child {

        .row {
          border-radius: 0 0 $global-radius $global-radius;
        }
      }

      .row {
        margin-bottom: 0;
        padding: 2rem 1.5rem;
        background-color: white;
        @extend .card;
      }
    }
  }
}
