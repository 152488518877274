//
// Buttons
// --------------------------------------------------
.button {
  text-transform: uppercase;
  font-family: $font-family-first;
  outline: none;

  &.success,
  &.warning,
  &.alert {
    color: $white;

    &:hover, &:focus, &:active {
      color: $white;
    }
  }

  &.hollow {

    &.primary {
      color: $primary-base;

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $primary-base;
        border-color: $primary-base;
      }
    }

    &.secondary {
      color: $secondary-base;

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $secondary-base;
        border-color: $secondary-base;
      }
    }

    &.success {
      color: $success-base;

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $success-base;
        border-color: $success-base;
      }
    }

    &.warning {
      color: $warning-base;

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $warning-base;
        border-color: $warning-base;
      }
    }

    &.alert {
      color: $danger-base;

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $danger-base;
        border-color: $danger-base;
      }
    }
  }
}

.button-link {
  text-decoration: underline;
}

.button-close {
  @extend .clear;
  @extend .secondary;
}

.account {

  .button-close {
    font-family: $body-font-family;
    font-weight: 400;
    color: $gray-base;
    margin-bottom: 0;
    text-transform: none;
    margin-top: 5px;
    padding-right: 0;
    padding-left: 0;

    .close-button {
      position: relative;
      display: inline-block;
      margin-left: 0.5rem;
      font-size: 1.4rem;
      font-weight: 300;
      right: 8px;
      top: 3px;
    }
  }
}


//Header
.button-account {
  @extend .hollow;
  @extend .primary;
  margin-left: 1rem;
}

.button-language,
.button-help {
 @extend .clear;
 @extend .secondary;
  font-family: $body-font-family;
  text-transform: none;
  margin-bottom: 0;
  padding: 0 0 0 1rem;
}

.button-language {
  padding-left: 0;
}

//Offers
.button-close-sidebar {

}

.button-offer-personal {
  @extend .success;
  @extend .expanded;
  margin-top: 1rem;
  margin-bottom: 0;
}

.button-offer-order {
  @extend .hollow;
  @extend .success;
  @extend .expanded;
  background-color: $white !important;

  &:hover {
    background-color: $success-base !important;
    color: $white !important;
    border-color: $success-base !important;
  }
}



//Search
.button-geoloc {

}

.button-unit {

}

.button-search {
  @extend .success;
  @extend .expanded;
}

.button-search-inline {
  @extend .success;
}

.button-search-edit {
  @extend .tiny;
  @extend .secondary;
  @extend .hollow;
  margin-bottom: 0;
  font-family: $font-family-second;
  border: none !important;
}

.button-search-close {
  @extend .tiny;
  @extend .secondary;
  @extend .hollow;
}



//Order
.button-user-back {
  @extend .small;
  @extend .secondary;
  background-color: $light-gray-base !important;
  text-transform: none;
  font-family: $body-font-family;
}

.button-user-login {
  @extend .success;
  @extend .expanded;
}

.button-user-first {
  @extend .success;
  @extend .expanded;
  font-size: 0.9rem !important;
  font-family: $font-family-first !important;
}
.button-user-second {
  @extend .hollow;
  @extend .success;

  &:hover {
    background-color: $success-base !important;
    color: $white !important;
    border-color: $success-base !important;
  }
}

.button-user-guest {
  @extend .success;
  @extend .expanded;
}

.button-user-facebook {
  background-color: #4065b3;
  font-family: arial, sans-serif !important;
  font-weight: bold;
  position: relative;
  text-align: center;
  padding-left: 30px !important;
  text-transform: none !important;
  @extend .expanded;

  img {
    position: absolute;
    left: 4px;
    top: 4px;
    height: calc(100% - 8px);
    width: auto;
  }

  &:hover {
    background-color: #4065b3;
  }
}

.button-user-google {
  background-color: #3e82f1;
  font-family: arial, sans-serif !important;
  font-weight: bold;
  position: relative;
  text-align: center;
  padding-left: 30px !important;
  border-radius: 2px;
  text-transform: none !important;
  @extend .expanded;

  img {
    position: absolute;
    left: 2px;
    top: 2px;
    height: calc(100% - 4px);
    width: auto;
  }

  &:hover {
    background-color: #3e82f1;
  }
}

.button-continue {
  @extend .success;
  margin-bottom: 0 !important;
  font-size: 1rem;
}

.button-order {
  @extend .success;
  float: right;
  margin-top: 1rem;
  margin-bottom: 0;

  @include breakpoint(small only) {
    width: 100%;
  }
}

.button-order-modify {
  @extend .tiny;
  @extend .secondary;
  @extend .hollow;
  margin-bottom: 0;
  font-family: $font-family-second;
  border: none !important;
}

.button-cart {
  @extend .hollow;
  @extend .primary;
  position: relative;

  &:hover {
    background-color: $primary-base !important;
    border-color: $primary-base !important;

    .fi {
      color: $white;
    }

    .badge {
      background-color: $white;
      border-color: $primary-base;
    }
  }

  .fi {
    color: $primary-base;
  }

  .badge {
    position: absolute;
    right: 10px;
    top: 9px;
    font-size: 0;
    color: transparent;
    width: 10px;
    height: 10px;
    border: solid 2px $white;
  }
}

.button-signin {
  @extend .success;
  @extend .expanded;
}

.button-logout {
  @extend .secondary;
  @extend .hollow;
}

.button-signup {
  @extend .primary;
  @extend .hollow;
  @extend .expanded;

  &:hover {
    background-color: $primary-base !important;
    color: $white !important;
    border-color: $primary-base !important;
  }
}

.button-reset-password {
  @extend .success;
  @extend .expanded;
}

.button-forgotten-password {
  @extend .success;
  @extend .expanded;
}


.button-action-info {
  @extend .clear;
  @extend .warning;
}

.button-user {
  @extend .clear;
}

.button-float {

}

.button-support-supplier {
  @extend .secondary;
  @extend .hollow;
  text-transform: none;
}

.button-profile-new-password {
  @extend .secondary;
  @extend .hollow;
  @extend .small;
  float: right;
}

.button-profile-update {
  @extend .success;
}

.button-prev,
.button-next {
  @extend .hollow;
  @extend .success;
  border: none !important;
  text-transform: none;
  font-family: $font-family-second;
}

.button-articles {
  @extend .secondary;
  @extend  .hollow;
  @extend .small;
}

.button-request,
.button-order-details {
  @extend .success;
  @extend .hollow;
  @extend .expanded;
  @extend .small;
  margin-top: 1rem;
  margin-bottom: 0;
  line-height: 1.2;
}

.button-file {
  background-color: $light-gray-base-bg;
  color: $gray-base;
  font-weight: 500;
  font-family: $body-font-family;
}

.button-send {
  @extend .success;
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.btn-message-submit {
  @extend .success;
}
