//
// Cards
// --------------------------------------------------

.card {
  overflow: visible;
  border: none;
  position: relative;
  margin-bottom: 3rem;
  background-color: $white;

  &.shadow {
    box-shadow: 0 5px 30px rgba(0,0,0,0.2);
  }

  &.card-light-grey {
    background-color: $lighter-gray-base;
  }

  /* &.card-striped {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  } */

  .card-header {
    background-color: transparent;
    border-bottom: solid 1px $light-gray-base-stroke;
    color: $gray-base;
    padding: 0.75rem 0;

    h1, h2, h3, h4, h5, h6 {
      color: $gray-base;
      margin-bottom: 0 !important;

      i {
        margin-right: 0.5rem;
      }
    }
  }

  /* header {
    padding: 0.75rem 0;

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0 !important;

      i {
        margin-right: 0.5rem;
      }
    }
  } */

  .card-section {
    border-bottom: solid 1px $light-gray-base-stroke;
    background-color: $white;

    &:first-child {
      border-radius: $global-radius $global-radius 0 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 $global-radius $global-radius;
    }

    h4 {
      color: $gray-base;
    }

    p, ul, li {
      color: lighten($gray-base, 10%);
    }

    .right {
      border-left: solid 1px $light-gray-base-stroke;

      @include breakpoint(small only) {
        border-left: none;
      }

      strong {
        font-size: 0.9rem;
        color: $gray-base;

        .label {
          margin-left: 0.5rem;
        }
      }
    }

    .list {
      margin-bottom: 0;
    }
  }

  .card-footer {

  }

  &.offer {
    box-shadow: 0 0 0 rgba(0,0,0,0);
    transition: all linear 0.3s;

    &:hover {
      box-shadow: 0 5px 30px rgba(0,0,0,0.2);
    }

    header.card-divider {
      background-color: $primary-base;
      color: $white;
      border-radius: $global-radius $global-radius 0 0;
      margin-bottom: 0.75rem;
      padding-top: 0;
      padding-bottom: 0;

      >.row {
        display: flex;
      }

      .prices {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-right: solid 1px rgba(255,255,255,0.35);

        .stat {
          font-family: $font-family-second;
          font-size: 2.2rem;

          @include breakpoint(large only) {
            font-size: 1.5rem;
          }
        }

        span {
          font-size: 0.75rem;
          line-height: 1.2;
          margin-bottom: 0;
          opacity: 0.75;
        }

        tooltip {

          tip-cont {
            a {
              color: $white;
              opacity: 0.75;
              font-size: 0.8rem;
            }
          }

          tip {
            min-width: 200px;
            box-shadow: 0 8px 15px rgba(0,0,0,0.25);
            background-color: $gray-base;
            padding: 0.5rem;

            tip-tip {
            }

            tip-arrow {
              border-bottom-color: $gray-base;
            }
          }
        }
      }

      .product {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 0.75rem;
        opacity: 0.75;
        text-align: right;

        ul {
          margin-bottom: 0;

          li {
            margin-bottom: 5px;
            line-height: 1.2;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.is-action {

      header.card-divider {

      }
    }

    .card-section {

      &.supplier {
        border-bottom: solid 1px darken($light-gray,10%);
        padding: 0 0 0.6rem 0 !important;
        margin-bottom: 1rem;

        img.supplier-image {
          width: auto;
          height: 200px;
          max-width: 200px;
          max-height: 50px;
          float: left;
          margin-right: 0.35rem;
        }

        .supplier-title {
          font-weight: bold;
          font-family: $body-font-family;
          color: $medium-gray;
          text-align: right;
          margin-bottom: 0.25rem;
        }
      }

      p {
        font-size: 0.85rem;
        line-height: 1.2;
        color: $medium-gray;
        margin-bottom: 1em;

        &.is-action {
          color: $danger-base !important;
          text-align: center;
          padding-bottom: 0.75rem;
          border-bottom: solid 1px  darken($light-gray, 5%);

          span {
            padding-top: 0.25rem;
            display: block;
            color: $secondary-base;
          }
        }
      }

      .info-link {
        font-size: 0.85rem;

        span {
          text-decoration: underline;
        }
      }

      &.sumary {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  footer {
    background-color: transparent;
  }

  &.offer-personal {
    max-width: 650px;
    margin: auto;

    .card-divider {
      background-color: transparent;
      color: $primary-base;
      text-align: center;
      padding: 0;
      text-transform: uppercase;
      margin-bottom: 1rem;
      border-bottom: solid 1px $light-gray;

      h2 {
        padding: 1rem;
        margin-bottom: 0;
      }
    }

    .sumary,
    .general-infos {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;

      p {
        font-size: 0.85rem;
        line-height: 1.2;
        color: $medium-gray;
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    form {

      .checkbox {
        > label {
          line-height: 1.2;
          color: $medium-gray;

          &:after {
            line-height: 1.8;
          }
        }
      }

      a {
        font-size: 0.75rem;
        color: $medium-gray;

        span {
          text-decoration: underline;
        }
      }
    }
  }
}


//Card Row
.card-row {
  @include grid-row;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}


// Card Striped
.card.card-striped {
  background-color: transparent;
  border-radius: none;

  .card-header {
    padding-bottom: 1rem;
  }

  &.shadow {
    box-shadow: none;

    >.card-section {
      box-shadow: 0 5px 30px rgba(0,0,0,0.2);
    }
  }

  > .card-section {
    background-color: $white;
    margin-bottom: 1.5rem;
    border-bottom: none;
    border-radius: $global-radius;
  }
}


// Card messages
.card.card-messages {

  .card-section {
    border: none;
    padding: 0.75rem !important;
    max-height: 500px;
    overflow-y: auto;

    &.card-messages-body {

      .no-messages {
        margin: 2.5rem 0;
        font-size: 0.9rem;
        line-height: 1.2;
      }

      .message {
        padding: 0.5rem;
        width: 80%;
        margin-bottom: 0.75rem;
        border-radius: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        &.client {
          background-color: rgba($success-base, 0.15);
          float: right;
          border-bottom-right-radius: 0;
        }

        &.supplier {
          float: left;
          background-color: $light-gray-base;
          border-bottom-left-radius: 0;
        }

        p {
          font-size: 0.9rem;
          line-height: 1.2;
          margin-bottom: 0.5rem;
        }

        small {
          display: block;
          font-size: 0.75rem;
          opacity: 0.75;
        }
      }
    }
  }

  .card-footer {
    padding: 0.5rem;
    background-color: rgba($success-base, 0.15);
    border-radius: 0 0 $global-radius $global-radius;

    .input-group {
      margin-bottom: 0;

      textarea {
        resize: vertical;
        margin-bottom: 0 !important;
        border: none;
        min-height: 50px;
        transition: all linear 0.3s;
        font-size: 0.9rem;
        max-height: 150px;

        &:focus {
          min-height: 100px;
        }

        @include breakpoint(small only) {
          width: 100%;
          border-radius: $global-radius $global-radius 0 0;
        }
      }

      .input-group-button {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        vertical-align: top;

        @include breakpoint(small only) {
          width: 100%;
          display: block;
        }

        .btn-message-submit {
          min-height: 50px;

          @include breakpoint(small only) {
            width: 100%;
            border-radius: 0 0 $global-radius $global-radius;
          }
        }
      }
    }
  }
}
