//
// Forms
// --------------------------------------------------

//Select2 Bootstrap
[theme=zf6].ui-select-container {
  .ui-select-match {
    .ui-select-toggle {
      border-color: lighten($medium-gray, 40%);
    }

  }
}

//Checkbox (awesome-foudation6-checkbox)
input[type="checkbox"],
input[type="radio"] {
  .checkbox & {
    &:checked + label::after{
      font-family: $font-family-icons !important;
      content: unquote("\"") + $fi-fms-check + unquote("\"") !important;
    }
  }

  &.styled:checked + label:after {
    font-family: $font-family-icons !important;
    content: unquote("\"") + $fi-fms-check + unquote("\"") !important;
  }
}

.checkbox {
  margin-bottom: $form-spacing;

  label {
    span {
      display: block;
      line-height: 1.2;
    }
  }
}


.input-group-button {
  background-color: $light-gray;

  button {
    background-color: transparent;
    color: $gray-base;
    font-family: $body-font-family;
    font-weight: 400;

    @include breakpoint(small only) {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }

    &.active {
      background-color: $primary-base;
      color: $white;
      font-weight: bold;
    }

    &:last-child {
      border-radius: $global-radius;
    }
  }
}


// Search
[name="searchForm"] {
  .ui-select-choices {
    min-width: 100% !important;

  }
}


//
.user-signup,
.order {

  form {

    .collapse-container {
      max-height: 200px;
      border: solid 1px darken($light-gray, 10%);
      padding: 10px 5px 5px 5px;

      p {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.account {

  .content {

    form {

      label {
        color: $secondary-base;
        text-align: right;

        @include breakpoint(small only) {
          text-align: left;
          margin-bottom: 0.25rem;
        }
      }
    }

  }
}

[type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea {
  box-shadow: none;
}
