//
// Alerts
// --------------------------------------------------
.callout-slide {
  > * > * {
    padding: $callout-margin;
  }

  .callout {
    margin: 0 !important;

    &.alert {
      box-shadow: none;
      padding: 0.5rem 0 0 0;
      background-color: lighten($warning-base, 33%);
    }
  }
}

.callout-news {
  margin-bottom: 0;
  border: none;
  background-color: $success-base;
  color: $white;
  text-align: center;
  font-weight: bold;
  border-radius: 0;
}

.callout {
  &.warning {
    border: none;
    color: $warning-base;
    padding-left: 3rem;

    i {
      position: absolute;
      left: 1rem;
      top: 0.8rem;
      margin-right: 1rem;
      font-size: 1.5rem;
    }
  }

  &.alert {
    text-align: center;
    color: $warning-base;
    border: none;
    box-shadow: 0 5px 25px rgba(0,0,0,0.2);
    margin: 2rem 0;
    min-height: 65px;
    padding: 1.5rem 1rem;
    background-color: $white;

    .h5 {
      color: $warning-base;
      text-transform: none;
      display: table;
      margin: auto;

      i {
        font-size: 3rem;
        width: 45px;
        height: 45px;
        display: table-cell;
        vertical-align: middle;
        text-align: right;
        padding-right: 1rem;
      }

      span {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
      }
    }
  }


}

ui-swiper {
  .swiper-container {

    .swiper-wrapper {

      .swiper-slide {

        .callout {


        }
      }
    }



    ui-swiper-prev {

    }

    ui-swiper-next {

    }
  }
}

