//
// Labels
// --------------------------------------------------

.label-action {
  @extend .success;

  color: $label-color !important;

  .card & {
    position: absolute;
    width: auto;
    z-index: -1;
    border-radius: 3px 3px 0 0;
    text-transform: uppercase;
    font-family: $font-family-first;
    height: 20px;
    top: -20px;
    left: 10px;
    font-size: 0.75rem;
    letter-spacing: 1px;
  }

  .card.home-search & {
    right: 10px;
    left: auto;
    letter-spacing: 0;
    font-size: 1rem;
    top: -28px;
    height: 28px;
  }

  a {
    color: $label-color;
  }
}


.label-request,
.label-messages {
  @extend .warning;
  border-radius: 50px;
  color: $white !important;
}


.label-status {
  background: none !important;
  color: $primary-base;
  padding: 0;
  margin-left: 0 !important;
  font-size: 0.9rem;

  &.alert {
    color: $danger-base;
  }

  &.warning {
    color: $warning-base;
  }

  &.success {
    color: $success-base;
  }
}
