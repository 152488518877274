

// Homepage

figure {
  position: relative;
  z-index: 1;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .figure-content {
    padding: $global-padding;

    .card {
      width: auto;
      max-width: 550px;

      @include breakpoint(small only) {
        width: 100%;
      }
    }
  }
}

.home-search {

  &.card {
    padding: 1.5rem;
    box-shadow: 0 15px 35px rgba(0,0,0,0.25);
    margin-left: 5%;
    margin-bottom: 2.5rem;
    margin-top: 2rem;

    @include breakpoint(small only) {
      margin-left: 0;
      padding: 1rem;
    }

    .card-divider {
      background-color: transparent;
      border-bottom: solid 1px $light-gray-base;
      text-align: center;
      padding: 0;
      padding-bottom: 1rem;

      h1 {
        font-size: 2rem;

        @include breakpoint(small only) {
          font-size: 1.5rem;
        }
      }
    }

    .card-section {
      padding: 0;
      padding-top: 1rem;

      .button-search {
        font-size: 1.2rem;
        margin-bottom: 0;
      }
    }
  }

  .input-group-button {

    tooltip {

      &._top._left {

        tip {
          left: 50px;
        }

        tip-arrow {
          border-bottom-color: $gray-base;
          border-top-color: $gray-base;
        }

      }
    }
  }
}

.banner {

  figure {

    @include breakpoint(small only) {
      min-height: 100px;
    }

    .figure-content {
      display: table;
      width: 100%;
      height: 100%;

      @include breakpoint(small only) {
        padding: 2rem 1rem;
      }

      [name="search"] {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}


section.home {

  > section {

    &.post-element {
      background-color: $light-gray-base-bg;

      header {

        h3 {

          &.subheader {
            color: darken($light-gray-base, 4%);
          }
        }
      }

      &:nth-child(2n) {
        background-color: $white;

        header {

          h3 {

            &.subheader {
              color: lighten($light-gray-base, 1%);
            }
          }
        }
      }
    }
  }
}

// Offers
.offers {
  display: flex;

  @include breakpoint(small only) {
    display: block;
  }

  .main {
    @include grid-column-collapse;

    @include breakpoint(small only) {
    }

    header {
      padding: 1rem;
      margin-bottom: 1.5rem;
      background-color: $white;

      search.is-mobile {
        h1 {
          font-size: 1.2rem;
          font-family: $font-family-second;
          color: $gray-base;
        }

        p {
          font-size: 0.85rem;
          color: lighten($gray-base, 15%);
          margin-bottom: 0;
          margin-top: 0.5rem;
        }

      }

      search.is-inline {

        .h1 {
          color: $gray-base;
          font-family: $font-family-second;
          font-size: 0.85rem;
          text-transform: uppercase;
          margin: 0;
          float: left;
          margin-top: 0.75rem;
          margin-right: 0.75rem;
        }

        form {

          input[type="submit"] {
            //float: left;
            margin-bottom: 0;
            margin-left: 0.75rem;
            padding: 0.72em 1em;
            background-color: transparent;
            border: solid 1px lighten($gray-base, 35%);
            color: lighten($gray-base, 35%);
            cursor: not-allowed;

            &.is-active {
              background-color: $success-base;
              color: $white;
              border-color: $success-base;
              cursor: pointer;

              &:hover {
                background-color: darken($success-base, 10%);
                border-color: darken($success-base, 10%);
              }
            }
          }

          .input-group-button {
            // background-color: $white;
          }
        }
      }
    }
  }
}


// Checkout
.order-checkout {
  min-height: 75vh;
  background-color: $white;

  .ui-select-toggle {
    border-color: $medium-gray !important;
  }

  .main {

    @include breakpoint(medium) {
      @include grid-column-collapse;
    }
  }

  .sidebar-active {
    display: flex;
    min-height: 75vh;

    @include breakpoint(small only) {
      display: block;
    }
  }

  .sidebar {

    .sidebar-header {
      height: calc(92px - 1rem);
      border-bottom: solid 2px lighten($medium-gray, 30%);

      h3 {
        font-size: 1.1rem;
      }
    }

    .sidebar-content {

      .details {
        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .sidebar-footer {
      background-color: darken($light-gray, 5%);
      padding-top: 1rem;
      padding-bottom: 1rem;

      .single-price {
        margin-bottom: 0.5rem;
      }

      .total-price {
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }

    .divider {
      background-color: $medium-gray;
      height: 1px;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }

    .h6 {
      color: $dark-gray;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
  }

  .order-checkout-details {
    padding: 1rem 15px 0 15px;
  }

  .order-checkout-details,
  .order-details-mobile {

    @include breakpoint(small only) {
      padding-left: 0;
      padding-right: 0;

      .details {

        p {
          font-size: 0.85rem;
          line-height: 1.4;
          margin-bottom: 0.5rem;

          &.lead {
            font-weight: bolder;
            padding: 0.5rem;
            background-color: $light-gray-base-bg;
            font-size: 0.95rem;
            border-radius: $global-radius;
            text-transform: uppercase;
          }
        }
      }
    }

    h1 {
      color: $gray-base;
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  .order-checkout-steps {
    width: 100%;
    background-color: $light-gray-base-bg;

    @include breakpoint(small only) {
      background-color: transparent;
    }
  }


  .accordion {
    margin: 2rem 15px 3rem 15px;
    border-radius: $global-radius;
    overflow: hidden;
    border: solid 1px $medium-gray-base-2;
    // box-shadow: 0 10px 25px rgba(0,0,0,0.2);

    @include breakpoint(small only) {
      margin: 1rem auto;
      width: 100%;
    }

    .accordion-item {
      border-top: solid 1px $medium-gray-base-2;

      &:first-child,
      &.info {
        border-top: none;
      }

      &.info {
        h6 {
          color: $gray-base;
          margin-top: 1rem;
        }
      }

      &.user.is-active {
        padding: 0;
        border-bottom: solid 1px $medium-gray-base-2;

        .accordion-content {
          padding: 2rem 1rem;

          .h5 {
            margin-bottom: 0 !important;
            color: $gray-base;
          }

          .button-user-second {
            margin-bottom: 0;
            margin-left: 1.5rem;
          }

          .button-user-guest {
            padding: 0.5rem 0.5rem 0.5rem 35px;
            font-weight: 400;
            font-family: $font-family-second;
            text-transform: none;
            position: relative;
            display: block;

            .fi {
              position: absolute;
              left: 5px;
              top: 3px;
              font-size: 24px;

              &:before {
                color: $white;
              }
            }
          }
        }
      }

      .accordion-title {
        background-color: transparent;
        color: $medium-gray-base-2;
        margin-bottom: 0;
        font-size: 1.3rem;
        text-transform: none;
        line-height: 25px;
        padding: 1rem;
        border: none;
        font-family: $font-family-second;
        font-weight: 400;

        .badge {
          background-color: $white;
          color: $medium-gray-base-2;
          font-size: 0.85rem;
          width: 30px;
          height: 30px;
          margin-right: 0.75rem;
          font-family: $font-family-first;
        }
      }

      .accordion-content {
        border: none ;
        padding: 0.5rem 1.5rem 2rem 1.5rem;

        @include breakpoint(small only) {
          padding: 0 0 1rem 0;
        }

        .order-checkout-message {
          margin-bottom: 1rem;
        }
      }

      &.is-active {

        .accordion-title {
          background-color: $white;
          color: $primary-base;
          font-family: $font-family-second;
          font-weight: 400;
          border-top: none;

          @include breakpoint(small only) {
            padding-left: .625rem;
            padding-right: .625rem;
          }

          .badge {
            color: $primary-base;
          }
        }
      }

      &.is-valid {
        border-left: solid 4px $success-base;

        .accordion-title {
          background-color: transparent;
          color: $gray-base;
          padding-left: calc(1rem - 4px);

          .badge {
            color: $success-base;
          }
        }

        i.fi-fms-pen {
          font-size: 1rem;
          color: $gray-base;
        }

        .preview {
          padding: 0 1rem 1rem 2rem;
          color: $gray-base;
          position: relative;
          overflow: hidden;

          ul {
            float: left;
            padding-right: 50px;
          }
        }
      }

      .tabs-content {
        border: none;
        position: relative;

        hr {
          border-color: $medium-gray-base-2;
          margin: 2rem auto;
        }

        .tabs-panel {
          padding: 0;

          &.tab-guest {

            .subheader {
              width: 100%;
            }
          }

          &.tab-signin {

            .lost-password {
              font-size: 0.85rem;
              text-decoration: underline;
              margin-bottom: 0.5rem;
              top: -10px;
              position: relative;
            }
          }

          header {
            margin-bottom: 1rem;
          }

          .h5 {
            font-size: 1rem;
            margin-bottom: 1.5rem;
            text-transform: uppercase;
          }

          .subtitle {
            position: relative;
            text-align: center;
            margin: 0 1rem;

            &:after {
              content: '';
              position: absolute;
              background-color: $medium-gray-base;
              width: 100%;
              height: 1px;
              top: 50%;
              left: 0;
            }
          }

          .h6 {
            font-family: $body-font-family;
            color: $medium-gray-base;
            font-size: 0.8rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
            margin-top: 1rem;
            position: relative;
            display: inline-block;
            background-color: $white;
            padding: 0 0.5rem;
            z-index: 2;
          }

          >.row {
            display: flex;

            @include breakpoint(small only) {
              display: block;
            }

            .first-col,
            .second-col {
              padding: 0 1rem;
            }

            .first-col {
              border-right: solid 1px $medium-gray-base-2;

              @include breakpoint(small only) {
                border-right: none;
                border-bottom: solid 1px $medium-gray-base-2;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
              }
            }
          }
        }

        .button-user-back {
          position: absolute;
          left: 0.5rem;
          top: -0.5rem;
        }

        .login-elements {
          display: flex;
          margin-bottom: 1rem;

          @include breakpoint(small only) {
            display: block;
          }

          .signin-form {

            [name="password"] {
              margin-bottom: 0.5rem;
            }

            .lost-password {
              font-size: 0.85rem;
              color: $medium-gray;
              margin-bottom: 1rem;
              text-decoration: underline;
            }

            .button-user-login {
              margin-bottom: 0;
            }
          }

          .login-social {

          }
        }
      }
    }
  }

  .input-group {
    .input-group-button {
      padding: 0;
    }
  }

  .button-user-facebook,
  .button-user-google {
    padding: 0.5rem;
  }

  .button-user-facebook {
    i {
      font-size: 1rem;
    }
  }

  .button-user-google {

    i {
      font-size: 1rem;
    }
  }

  .info {

    &.is-active, &.is-valid {

      .accordion-title {
        border-radius: $global-radius $global-radius 0 0;
      }
    }
  }

  .order {

    .order-panel {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      textarea {
        border-color: $medium-gray;
        height: 120px;

        @include breakpoint(small only) {
          height: 75px;
        }
      }

      a {
        text-decoration: underline;
      }

      .choose-password {
        margin-top: 2rem;
        margin-bottom: 1rem;

        .columns {
          padding-right: 1rem;

          @include breakpoint(small only) {
            padding-right: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        h5 {
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }

        @include breakpoint(small only) {
          margin-right: 0;
        }
      }

      .collapse-container {
        margin-bottom: 1rem;
        border-radius: $global-radius;
      }
    }

    .order-submit {

      .callout {

      }

      .button-order {
        font-size: 1.5rem;
      }
    }
  }

}

// Order-Pending
.order-pending {
  padding-top: 3rem;

  .callout {

    &.callout-message {

      &.warning {
        color: $warning-color;

        h1 {
          color: $warning-color;
          font-family: $body-font-family;
          padding-left: 3rem;
          line-height: 1.5;

          i {
            font-size: 3rem;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
          }
        }
      }

    }
  }
}


// Confirm
.order-confirm {

  .callout {
    text-align: center;
    color: $success-base;
    border: none;
    box-shadow: 0 5px 25px rgba(0,0,0,0.2);
    margin: 2rem 0;
    min-height: 65px;
    padding: 1.5rem 1rem;
    background-color: $white;

    .h5 {
      color: $success-base;
      text-transform: uppercase;
      display: table;
      margin: auto;

      @include breakpoint(small only) {
        display: block;
      }

      i {
        font-size: 4rem;
        width: 65px;
        height: 65px;
        display: table-cell;
        vertical-align: middle;
        text-align: right;
        padding-right: 1rem;

        @include breakpoint(small only) {
          width: 100%;
          text-align: center;
          padding-right: 0;
          display: block;
        }
      }

      span {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
      }
    }
  }

  .preview,
  .support {
    margin-top: 1rem;
  }

  .preview {

    .h5 {
      text-transform: uppercase;
    }

    .h6 {
      text-transform: uppercase;
      font-size: 1rem;
    }

    .card-divider {
      background-color: $gray-base;
      color: $white;
      border-radius: 0 0 $global-radius $global-radius;

      .single-price {
        margin-bottom: 0.5rem;
      }

      .total-price {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-family: $font-family-second;
      }
    }

    .details {
      li {
        margin-bottom: 0.5rem;
      }
    }

    .comment {
      padding-top: 1rem;
      border-top: solid 1px darken($light-gray-base, 15%);
    }
  }
}


// Account
.account {
  background-color: $light-gray-base-bg;

  &.sidebar-active {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    min-height: 75vh;
  }

  .main {
    padding-bottom: 2rem;

    @include breakpoint(small only) {
      padding-bottom: 0;
      background-color: $light-gray-base-bg;
    }

    header {
      border-bottom: solid 1px darken($light-gray-base-stroke, 3%);
      margin-bottom: 2rem;

      h1 {
        font-family: $font-family-second;
        font-size: 1.4rem;
        padding-top: 1.2rem;
        padding-bottom: 1rem;
        margin-bottom: 0;

        small {
          font-family: $body-font-family;
          font-weight: 400;
          font-size: 1rem;
          border-left: solid 1px $medium-gray-base;
          padding-left: 0.75rem;
          margin-left: 0.75rem;
        }
      }
    }

    section {

      header {
        h6 {
          color: $gray-base;
          font-size: 1.1rem;
        }
      }
    }

    [auth] {
      position: relative;
      overflow: hidden;

      .social-login {
        padding: 0;
        margin: 0;

        .columns {
          &:first-child {
            padding-left: 0;
            padding-right: 0.5rem;
          }

          &:last-child {
            padding-right: 0;
            padding-left: 0.5rem;
          }
        }

        .button-user-facebook,
        .button-user-google {
          padding: 0.5rem;
          font-size: 0.75rem;
          margin-bottom: 0;
        }
      }

      .dash-divider {
        position: relative;
        text-align: center;
        margin: 0.5rem 0;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: $light-gray;
          top: 50%;
          left: 0;
        }

        span {
          position: relative;
          margin: auto;
          background: $white;
          padding: 0 0.5rem;
          font-size: 0.75rem;
          font-family: $body-font-family;
          color: $medium-gray;
        }
      }

      .content {
        margin-bottom: 1rem;

        form {
          position: relative;

          input {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 0.5rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .button-link,
          .lost-password {
            font-size: 0.75rem;
            color: $gray-base;
            text-decoration: underline;
            font-family: $body-font-family;
            text-transform: none;
            padding-right: 0;
            padding-top: 0;
            margin-bottom: 0.5rem;
          }

          .button-signin {
            margin-bottom: 0;
          }
        }
      }

      footer {
        padding: 1rem;
        background-color: $light-gray;

        .button {
          background-color: $white;
        }
      }
    }
  }

  .content {
    padding-bottom: 1rem;

    h1, h2, h3, h4, h5, h6 {
      color: $secondary-base;
      margin-bottom: 1rem;
    }

    fieldset {
      position: relative;
      overflow: hidden;
      margin-bottom: 1rem;
    }
  }

  .order-details {
    font-size: 0.9rem !important;

    header {
      padding: 0.95rem 0;
      margin-bottom: 1rem;
    }

    .details {
      li {
        margin-bottom: 0.5rem;
      }
    }

    .h6 {
      margin-top: 1rem;
      text-transform: uppercase;
    }

    footer {
      margin-top: 1rem;
      margin-bottom: 3rem;

      @include breakpoint(small only) {
        padding-bottom: 1.5rem !important;
        margin-bottom: 1.5rem;
        border-bottom: solid 1px $light-gray-base-stroke;
      }

      &.total {
        border-radius: $global-radius;
        background-color: $gray-base;
        color: $white;
        padding: 1rem;

        .total-price {
          text-transform: uppercase;
          font-family: $font-family-second;
          font-size: 1.5rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
