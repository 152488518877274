// Erreur 404
.error404 {
  display: flex;
  align-items: center;
  min-height: 70vh;

  h1 {
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 0;
    background-color: transparent;
    text-align: center;
    position: relative;
    color: $primary-base;
    z-index: 1;

    span {
      display: block;
      margin-top: 63px;

      @include breakpoint(small only) {
        margin-top: 37px;
      }
    }

    &:before {
      content: '404';
      position: absolute;
      font-family: $font-family-second;
      font-weight: bold;
      font-size: 300px;
      line-height: 0;
      color: $white;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @include breakpoint(small only) {
        font-size: 40vw;
      }
    }
  }
}
