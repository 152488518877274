//
// Font Icons
// Base on Font Awesome style
// --------------------------------------------------

.fi {
  display: inline-block;
  font: normal normal normal #{$icons-font-size}/#{$icons-font-height} $font-family-icons;
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* makes the font 33% larger relative to the icon container */
.fi-lg {
  font-size: 1.33333333em !important;
  line-height: 0.75em !important;
  vertical-align: -15% !important;
}
.fi-2x {
  font-size: 2em !important;
}
.fi-3x {
  font-size: 3em !important;
}
.fi-4x {
  font-size: 4em !important;
}
.fi-5x {
  font-size: 5em !important;
}
.fi-fw {
  width: 1.28571429em;
  text-align: center;
}
.fi-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fi-ul > li {
  position: relative;
}
.fi-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fi-li.fi-lg {
  left: -1.85714286em;
}
.fi-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.fi-pull-left {
  float: left;
}
.fi-pull-right {
  float: right;
}
.fi.fi-pull-left {
  margin-right: .3em;
}
.fi.fi-pull-right {
  margin-left: .3em;
}
.fi-spin {
  -webkit-animation: fi-spin 1s infinite linear;
  animation: fi-spin 1s infinite linear;
}
.fi-pulse {
  -webkit-animation: fi-spin 1s infinite steps(8);
  animation: fi-spin 1s infinite steps(8);
}
@-webkit-keyframes fi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fi-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fi-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fi-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fi-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fi-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fi-rotate-90,
:root .fi-rotate-180,
:root .fi-rotate-270,
:root .fi-flip-horizontal,
:root .fi-flip-vertical {
  filter: none;
}
.fi-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fi-stack-1x,
.fi-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fi-stack-1x {
  line-height: inherit;
}
.fi-stack-2x {
  font-size: 2em !important;
}
.fi-inverse {
  color: #ffffff;
}

