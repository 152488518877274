/* line 4, ../../app/styles/ngAnimate/common/base.scss */
.nga-default {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
/* line 6, ../../app/styles/ngAnimate/common/base.scss */
.nga-default.ng-hide-add, .nga-default.ng-hide-remove {
  display: block !important;
}

/* line 11, ../../app/styles/ngAnimate/common/base.scss */
.nga-slow {
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}
/* line 13, ../../app/styles/ngAnimate/common/base.scss */
.nga-slow.ng-hide-add, .nga-slow.ng-hide-remove {
  display: block !important;
}

/* line 18, ../../app/styles/ngAnimate/common/base.scss */
.nga-fast {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/* line 20, ../../app/styles/ngAnimate/common/base.scss */
.nga-fast.ng-hide-add, .nga-fast.ng-hide-remove {
  display: block !important;
}

/* line 3, ../../app/styles/ngAnimate/animations/nga.collapse.scss */
.nga-collapse {
  overflow: hidden;
}
/* line 7, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-collapse.ng-enter:not(.ng-enter-active), .nga-collapse.ng-hide-remove:not(.ng-hide-remove-active) {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-collapse.ng-leave.ng-leave-active, .nga-collapse.ng-hide-add.ng-hide-add-active {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-collapse.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-fade.ng-enter, .nga-fade.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-fade.ng-enter.ng-enter-active, .nga-fade.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-fade.ng-leave.ng-leave-active, .nga-fade.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-fade.ng-leave, .nga-fade.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-fade.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-fade.ng-move.ng-move-active {
  opacity: 1;
}

/* line 4, ../../app/styles/ngAnimate/animations/nga.rotate-down.scss */
.nga-rotate-down {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.rotate-down.base.scss */
.nga-rotate-down.ng-enter, .nga-rotate-down.ng-hide-remove {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  -o-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
/* line 17, ../../app/styles/ngAnimate/animations/_nga.rotate-down.base.scss */
.nga-rotate-down.ng-enter.ng-enter-active, .nga-rotate-down.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.rotate-down.base.scss */
.nga-rotate-down.ng-leave.ng-leave-active, .nga-rotate-down.ng-hide-add.ng-hide-add-active {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  -o-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
/* line 25, ../../app/styles/ngAnimate/animations/_nga.rotate-down.base.scss */
.nga-rotate-down.ng-leave, .nga-rotate-down.ng-hide-add {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 30, ../../app/styles/ngAnimate/animations/_nga.rotate-down.base.scss */
.nga-rotate-down.ng-move {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  -o-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
/* line 33, ../../app/styles/ngAnimate/animations/_nga.rotate-down.base.scss */
.nga-rotate-down.ng-move.ng-move-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-down.ng-enter, .nga-rotate-down.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-down.ng-enter.ng-enter-active, .nga-rotate-down.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-down.ng-leave.ng-leave-active, .nga-rotate-down.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-down.ng-leave, .nga-rotate-down.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-down.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-down.ng-move.ng-move-active {
  opacity: 1;
}

/* line 4, ../../app/styles/ngAnimate/animations/nga.rotate-left.scss */
.nga-rotate-left {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.rotate-left.base.scss */
.nga-rotate-left.ng-enter, .nga-rotate-left.ng-hide-remove {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
/* line 17, ../../app/styles/ngAnimate/animations/_nga.rotate-left.base.scss */
.nga-rotate-left.ng-enter.ng-enter-active, .nga-rotate-left.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.rotate-left.base.scss */
.nga-rotate-left.ng-leave.ng-leave-active, .nga-rotate-left.ng-hide-add.ng-hide-add-active {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
/* line 25, ../../app/styles/ngAnimate/animations/_nga.rotate-left.base.scss */
.nga-rotate-left.ng-leave, .nga-rotate-left.ng-hide-add {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 30, ../../app/styles/ngAnimate/animations/_nga.rotate-left.base.scss */
.nga-rotate-left.ng-move {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
/* line 33, ../../app/styles/ngAnimate/animations/_nga.rotate-left.base.scss */
.nga-rotate-left.ng-move.ng-move-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-left.ng-enter, .nga-rotate-left.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-left.ng-enter.ng-enter-active, .nga-rotate-left.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-left.ng-leave.ng-leave-active, .nga-rotate-left.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-left.ng-leave, .nga-rotate-left.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-left.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-left.ng-move.ng-move-active {
  opacity: 1;
}

/* line 4, ../../app/styles/ngAnimate/animations/nga.rotate-right.scss */
.nga-rotate-right {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.rotate-right.base.scss */
.nga-rotate-right.ng-enter, .nga-rotate-right.ng-hide-remove {
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  -o-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
/* line 17, ../../app/styles/ngAnimate/animations/_nga.rotate-right.base.scss */
.nga-rotate-right.ng-enter.ng-enter-active, .nga-rotate-right.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.rotate-right.base.scss */
.nga-rotate-right.ng-leave.ng-leave-active, .nga-rotate-right.ng-hide-add.ng-hide-add-active {
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  -o-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
/* line 25, ../../app/styles/ngAnimate/animations/_nga.rotate-right.base.scss */
.nga-rotate-right.ng-leave, .nga-rotate-right.ng-hide-add {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 30, ../../app/styles/ngAnimate/animations/_nga.rotate-right.base.scss */
.nga-rotate-right.ng-move {
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  -o-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
/* line 33, ../../app/styles/ngAnimate/animations/_nga.rotate-right.base.scss */
.nga-rotate-right.ng-move.ng-move-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-right.ng-enter, .nga-rotate-right.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-right.ng-enter.ng-enter-active, .nga-rotate-right.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-right.ng-leave.ng-leave-active, .nga-rotate-right.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-right.ng-leave, .nga-rotate-right.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-right.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-right.ng-move.ng-move-active {
  opacity: 1;
}

/* line 4, ../../app/styles/ngAnimate/animations/nga.rotate-up.scss */
.nga-rotate-up {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.rotate-up.base.scss */
.nga-rotate-up.ng-enter, .nga-rotate-up.ng-hide-remove {
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
}
/* line 17, ../../app/styles/ngAnimate/animations/_nga.rotate-up.base.scss */
.nga-rotate-up.ng-enter.ng-enter-active, .nga-rotate-up.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.rotate-up.base.scss */
.nga-rotate-up.ng-leave.ng-leave-active, .nga-rotate-up.ng-hide-add.ng-hide-add-active {
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
}
/* line 25, ../../app/styles/ngAnimate/animations/_nga.rotate-up.base.scss */
.nga-rotate-up.ng-leave, .nga-rotate-up.ng-hide-add {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 30, ../../app/styles/ngAnimate/animations/_nga.rotate-up.base.scss */
.nga-rotate-up.ng-move {
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
}
/* line 33, ../../app/styles/ngAnimate/animations/_nga.rotate-up.base.scss */
.nga-rotate-up.ng-move.ng-move-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-up.ng-enter, .nga-rotate-up.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-up.ng-enter.ng-enter-active, .nga-rotate-up.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-up.ng-leave.ng-leave-active, .nga-rotate-up.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-up.ng-leave, .nga-rotate-up.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-up.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-rotate-up.ng-move.ng-move-active {
  opacity: 1;
}

/* line 5, ../../app/styles/ngAnimate/animations/nga.slide-down.scss */
.nga-slide-down {
  position: relative;
  overflow: hidden;
  overflow: hidden;
}
/* line 13, ../../app/styles/ngAnimate/animations/_nga.slide-down.base.scss */
.nga-slide-down.ng-enter, .nga-slide-down.ng-hide-remove {
  top: 50px;
}
/* line 16, ../../app/styles/ngAnimate/animations/_nga.slide-down.base.scss */
.nga-slide-down.ng-enter.ng-enter-active, .nga-slide-down.ng-hide-remove.ng-hide-remove-active {
  top: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.slide-down.base.scss */
.nga-slide-down.ng-leave.ng-leave-active, .nga-slide-down.ng-hide-add.ng-hide-add-active {
  top: 50px;
}
/* line 24, ../../app/styles/ngAnimate/animations/_nga.slide-down.base.scss */
.nga-slide-down.ng-leave, .nga-slide-down.ng-hide-add {
  top: 0;
}
/* line 29, ../../app/styles/ngAnimate/animations/_nga.slide-down.base.scss */
.nga-slide-down.ng-move {
  top: 50px;
}
/* line 32, ../../app/styles/ngAnimate/animations/_nga.slide-down.base.scss */
.nga-slide-down.ng-move.ng-move-active {
  top: 0;
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-down.ng-enter, .nga-slide-down.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-down.ng-enter.ng-enter-active, .nga-slide-down.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-down.ng-leave.ng-leave-active, .nga-slide-down.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-down.ng-leave, .nga-slide-down.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-down.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-down.ng-move.ng-move-active {
  opacity: 1;
}
/* line 7, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-down.ng-enter:not(.ng-enter-active), .nga-slide-down.ng-hide-remove:not(.ng-hide-remove-active) {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-down.ng-leave.ng-leave-active, .nga-slide-down.ng-hide-add.ng-hide-add-active {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-down.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* line 5, ../../app/styles/ngAnimate/animations/nga.slide-left.scss */
.nga-slide-left {
  position: relative;
  overflow: hidden;
  overflow: hidden;
}
/* line 13, ../../app/styles/ngAnimate/animations/_nga.slide-left.base.scss */
.nga-slide-left.ng-enter, .nga-slide-left.ng-hide-remove {
  right: 100%;
}
/* line 16, ../../app/styles/ngAnimate/animations/_nga.slide-left.base.scss */
.nga-slide-left.ng-enter.ng-enter-active, .nga-slide-left.ng-hide-remove.ng-hide-remove-active {
  right: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.slide-left.base.scss */
.nga-slide-left.ng-leave.ng-leave-active, .nga-slide-left.ng-hide-add.ng-hide-add-active {
  right: 100%;
}
/* line 24, ../../app/styles/ngAnimate/animations/_nga.slide-left.base.scss */
.nga-slide-left.ng-leave, .nga-slide-left.ng-hide-add {
  right: 0;
}
/* line 29, ../../app/styles/ngAnimate/animations/_nga.slide-left.base.scss */
.nga-slide-left.ng-move {
  right: 100%;
}
/* line 32, ../../app/styles/ngAnimate/animations/_nga.slide-left.base.scss */
.nga-slide-left.ng-move.ng-move-active {
  right: 0;
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-left.ng-enter, .nga-slide-left.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-left.ng-enter.ng-enter-active, .nga-slide-left.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-left.ng-leave.ng-leave-active, .nga-slide-left.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-left.ng-leave, .nga-slide-left.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-left.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-left.ng-move.ng-move-active {
  opacity: 1;
}
/* line 7, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-left.ng-enter:not(.ng-enter-active), .nga-slide-left.ng-hide-remove:not(.ng-hide-remove-active) {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-left.ng-leave.ng-leave-active, .nga-slide-left.ng-hide-add.ng-hide-add-active {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-left.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* line 5, ../../app/styles/ngAnimate/animations/nga.slide-right.scss */
.nga-slide-right {
  position: relative;
  overflow: hidden;
  overflow: hidden;
}
/* line 13, ../../app/styles/ngAnimate/animations/_nga.slide-right.base.scss */
.nga-slide-right.ng-enter, .nga-slide-right.ng-hide-remove {
  left: 100%;
}
/* line 16, ../../app/styles/ngAnimate/animations/_nga.slide-right.base.scss */
.nga-slide-right.ng-enter.ng-enter-active, .nga-slide-right.ng-hide-remove.ng-hide-remove-active {
  left: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.slide-right.base.scss */
.nga-slide-right.ng-leave.ng-leave-active, .nga-slide-right.ng-hide-add.ng-hide-add-active {
  left: 100%;
}
/* line 24, ../../app/styles/ngAnimate/animations/_nga.slide-right.base.scss */
.nga-slide-right.ng-leave, .nga-slide-right.ng-hide-add {
  left: 0;
}
/* line 29, ../../app/styles/ngAnimate/animations/_nga.slide-right.base.scss */
.nga-slide-right.ng-move {
  left: 100%;
}
/* line 32, ../../app/styles/ngAnimate/animations/_nga.slide-right.base.scss */
.nga-slide-right.ng-move.ng-move-active {
  left: 0;
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-right.ng-enter, .nga-slide-right.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-right.ng-enter.ng-enter-active, .nga-slide-right.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-right.ng-leave.ng-leave-active, .nga-slide-right.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-right.ng-leave, .nga-slide-right.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-right.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-right.ng-move.ng-move-active {
  opacity: 1;
}
/* line 7, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-right.ng-enter:not(.ng-enter-active), .nga-slide-right.ng-hide-remove:not(.ng-hide-remove-active) {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-right.ng-leave.ng-leave-active, .nga-slide-right.ng-hide-add.ng-hide-add-active {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-right.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* line 5, ../../app/styles/ngAnimate/animations/nga.slide-up.scss */
.nga-slide-up {
  position: relative;
  overflow: hidden;
  overflow: hidden;
}
/* line 13, ../../app/styles/ngAnimate/animations/_nga.slide-up.base.scss */
.nga-slide-up.ng-enter, .nga-slide-up.ng-hide-remove {
  bottom: 50px;
}
/* line 16, ../../app/styles/ngAnimate/animations/_nga.slide-up.base.scss */
.nga-slide-up.ng-enter.ng-enter-active, .nga-slide-up.ng-hide-remove.ng-hide-remove-active {
  bottom: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.slide-up.base.scss */
.nga-slide-up.ng-leave.ng-leave-active, .nga-slide-up.ng-hide-add.ng-hide-add-active {
  bottom: 50px;
}
/* line 24, ../../app/styles/ngAnimate/animations/_nga.slide-up.base.scss */
.nga-slide-up.ng-leave, .nga-slide-up.ng-hide-add {
  bottom: 0;
}
/* line 29, ../../app/styles/ngAnimate/animations/_nga.slide-up.base.scss */
.nga-slide-up.ng-move {
  bottom: 50px;
}
/* line 32, ../../app/styles/ngAnimate/animations/_nga.slide-up.base.scss */
.nga-slide-up.ng-move.ng-move-active {
  bottom: 0;
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-up.ng-enter, .nga-slide-up.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-up.ng-enter.ng-enter-active, .nga-slide-up.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-up.ng-leave.ng-leave-active, .nga-slide-up.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-up.ng-leave, .nga-slide-up.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-up.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-slide-up.ng-move.ng-move-active {
  opacity: 1;
}
/* line 7, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-up.ng-enter:not(.ng-enter-active), .nga-slide-up.ng-hide-remove:not(.ng-hide-remove-active) {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-up.ng-leave.ng-leave-active, .nga-slide-up.ng-hide-add.ng-hide-add-active {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 21, ../../app/styles/ngAnimate/animations/_nga.collapse.base.scss */
.nga-slide-up.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* line 3, ../../app/styles/ngAnimate/animations/nga.squash-left.scss */
.nga-squash-left {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  left: 0;
}
/* line 17, ../../app/styles/ngAnimate/animations/_nga.squash-left.base.scss */
.nga-squash-left.ng-enter, .nga-squash-left.ng-hide-remove {
  width: 0;
  right: 100%;
}
/* line 20, ../../app/styles/ngAnimate/animations/_nga.squash-left.base.scss */
.nga-squash-left.ng-enter:not(.ng-enter-active), .nga-squash-left.ng-hide-remove:not(.ng-hide-remove-active) {
  padding-left: 0;
  padding-right: 0;
}
/* line 24, ../../app/styles/ngAnimate/animations/_nga.squash-left.base.scss */
.nga-squash-left.ng-enter.ng-enter-active, .nga-squash-left.ng-hide-remove.ng-hide-remove-active {
  width: 100%;
  right: 0;
}
/* line 29, ../../app/styles/ngAnimate/animations/_nga.squash-left.base.scss */
.nga-squash-left.ng-leave, .nga-squash-left.ng-hide-add {
  width: 100%;
  right: 0;
}
/* line 32, ../../app/styles/ngAnimate/animations/_nga.squash-left.base.scss */
.nga-squash-left.ng-leave.ng-leave-active, .nga-squash-left.ng-hide-add.ng-hide-add-active {
  width: 0;
  right: 100%;
  padding-left: 0;
  padding-right: 0;
}
/* line 39, ../../app/styles/ngAnimate/animations/_nga.squash-left.base.scss */
.nga-squash-left.ng-move {
  width: 0;
  right: 100%;
}
/* line 42, ../../app/styles/ngAnimate/animations/_nga.squash-left.base.scss */
.nga-squash-left.ng-move.ng-move-active {
  width: 100%;
  right: 0;
}

/* line 3, ../../app/styles/ngAnimate/animations/nga.squash-right.scss */
.nga-squash-right {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  right: 0;
}
/* line 17, ../../app/styles/ngAnimate/animations/_nga.squash-right.base.scss */
.nga-squash-right.ng-enter, .nga-squash-right.ng-hide-remove {
  width: 0;
  left: 100%;
}
/* line 20, ../../app/styles/ngAnimate/animations/_nga.squash-right.base.scss */
.nga-squash-right.ng-enter:not(.ng-enter-active), .nga-squash-right.ng-hide-remove:not(.ng-hide-remove-active) {
  padding-left: 0;
  padding-right: 0;
}
/* line 24, ../../app/styles/ngAnimate/animations/_nga.squash-right.base.scss */
.nga-squash-right.ng-enter.ng-enter-active, .nga-squash-right.ng-hide-remove.ng-hide-remove-active {
  width: 100%;
  left: 0;
}
/* line 29, ../../app/styles/ngAnimate/animations/_nga.squash-right.base.scss */
.nga-squash-right.ng-leave, .nga-squash-right.ng-hide-add {
  width: 100%;
  left: 0;
}
/* line 32, ../../app/styles/ngAnimate/animations/_nga.squash-right.base.scss */
.nga-squash-right.ng-leave.ng-leave-active, .nga-squash-right.ng-hide-add.ng-hide-add-active {
  width: 0;
  left: 100%;
  padding-left: 0;
  padding-right: 0;
}
/* line 39, ../../app/styles/ngAnimate/animations/_nga.squash-right.base.scss */
.nga-squash-right.ng-move {
  width: 0;
  left: 100%;
}
/* line 42, ../../app/styles/ngAnimate/animations/_nga.squash-right.base.scss */
.nga-squash-right.ng-move.ng-move-active {
  width: 100%;
  left: 0;
}

/* line 4, ../../app/styles/ngAnimate/animations/nga.squash-vertical.scss */
.nga-squash-vertical {
  position: relative;
  overflow: hidden;
  bottom: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.squash-vertical.base.scss */
.nga-squash-vertical.ng-enter, .nga-squash-vertical.ng-hide-remove {
  top: 100%;
}
/* line 17, ../../app/styles/ngAnimate/animations/_nga.squash-vertical.base.scss */
.nga-squash-vertical.ng-enter:not(.ng-enter-active), .nga-squash-vertical.ng-hide-remove:not(.ng-hide-remove-active) {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.squash-vertical.base.scss */
.nga-squash-vertical.ng-enter.ng-enter-active, .nga-squash-vertical.ng-hide-remove.ng-hide-remove-active {
  top: 0;
}
/* line 27, ../../app/styles/ngAnimate/animations/_nga.squash-vertical.base.scss */
.nga-squash-vertical.ng-leave, .nga-squash-vertical.ng-hide-add {
  top: 0;
}
/* line 30, ../../app/styles/ngAnimate/animations/_nga.squash-vertical.base.scss */
.nga-squash-vertical.ng-leave.ng-leave-active, .nga-squash-vertical.ng-hide-add.ng-hide-add-active {
  top: 100%;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* line 38, ../../app/styles/ngAnimate/animations/_nga.squash-vertical.base.scss */
.nga-squash-vertical.ng-move {
  top: 100%;
}
/* line 41, ../../app/styles/ngAnimate/animations/_nga.squash-vertical.base.scss */
.nga-squash-vertical.ng-move.ng-move-active {
  top: 0;
}
/* line 2, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-squash-vertical.ng-enter, .nga-squash-vertical.ng-hide-remove {
  opacity: 0;
}
/* line 6, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-squash-vertical.ng-enter.ng-enter-active, .nga-squash-vertical.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/* line 11, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-squash-vertical.ng-leave.ng-leave-active, .nga-squash-vertical.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 14, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-squash-vertical.ng-leave, .nga-squash-vertical.ng-hide-add {
  opacity: 1;
}
/* line 19, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-squash-vertical.ng-move {
  opacity: 0;
}
/* line 22, ../../app/styles/ngAnimate/animations/_nga.fade.base.scss */
.nga-squash-vertical.ng-move.ng-move-active {
  opacity: 1;
}

/* line 5, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-add.ng-enter-stagger {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}

/* line 11, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-leave.ng-leave-stagger {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}

/* line 17, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-move.ng-move-stagger {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}

/* line 5, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger.ng-enter-stagger {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
/* line 11, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger.ng-leave-stagger {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
/* line 17, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger.ng-move-stagger {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}

/* line 5, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-fast.ng-enter-stagger {
  -webkit-transition-delay: 20ms;
  -moz-transition-delay: 20ms;
  -o-transition-delay: 20ms;
  transition-delay: 20ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
/* line 11, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-fast.ng-leave-stagger {
  -webkit-transition-delay: 20ms;
  -moz-transition-delay: 20ms;
  -o-transition-delay: 20ms;
  transition-delay: 20ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
/* line 17, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-fast.ng-move-stagger {
  -webkit-transition-delay: 20ms;
  -moz-transition-delay: 20ms;
  -o-transition-delay: 20ms;
  transition-delay: 20ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}

/* line 5, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-slow.ng-enter-stagger {
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
/* line 11, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-slow.ng-leave-stagger {
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
/* line 17, ../../app/styles/ngAnimate/modifiers/nga.stagger.scss */
.nga-stagger-slow.ng-move-stagger {
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
