//
// Grids
// --------------------------------------------------

// Foundation Grid system
@if $global-flexbox == false {

  .main {
    position: relative;
    @include grid-column($main-columns);
    min-height: 1px;

    .sidebar-active & {
      //Only medium and up
      @include breakpoint(medium) {
        @include grid-column($main-columns - $sidebar-columns);
      }

      @include breakpoint(xxlarge) {
        @include grid-column($main-columns - $sidebar-columns + 1);
      }
    }
  }

  .sidebar {
    //Only medium and up
    @include breakpoint(medium) {
      @include grid-column($sidebar-columns);
    }

    @include breakpoint(xxlarge) {
      @include grid-column($sidebar-columns - 1);
    }

    @include grid-column($main-columns);
    min-height: 1px;
  }
} @else {

  .main {
    @include flex-grid-column($main-columns);

    .sidebar-active & {
      //Only medium and up
      @include breakpoint(medium) {

        @include flex-grid-column($main-columns - $sidebar-columns);
      }
    }
  }

  .sidebar {
    @include flex-grid-column($main-columns);

    //Only medium and up
    @include breakpoint(medium) {
      @include flex-grid-column($sidebar-columns);
    }
  }

}
