.bottom-footer {
  background-color: $primary-base;
  color: $white;

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .footer-main {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .menu-primary {
      font-family: $font-family-second;
    }
  }

  .copyright {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: darken($primary-base, 5%);
    font-size: 0.85rem;

    p {
      margin-bottom: 0;
      opacity: 0.5;
    }
  }
}
