
.ui-select-zf6 {

  .ui-select-toggle {

    &.button {
      height: 2.5rem;
      text-transform: none;
      background-color: $white;
    }
  }

  &.open {

  }
}

.form-inline {

  .ui-select-zf6 {
    margin-right: 0.75rem;

    .ui-select-toggle {

      &.button {
        margin-bottom: 0;
      }
    }

    .ui-select-dropdown {
      margin-top: 0;
    }

    .ui-select-search {
      min-width: 200px;
    }
  }

  .form-group {

    .input-group {
      margin-bottom: 0;

      .ui-select-zf6 {
        margin-right: 0;
      }
    }
  }

  [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea {
    margin-bottom: 0;
  }
}


.input-group {

  .ui-select-zf6 {

    .ui-select-match {
      width: 100%;
      position: absolute;
      top: 0;
    }
  }

  .input-group-button {
    padding: 0.25rem;

    &:last-child {
      border-radius: 0 $global-radius $global-radius 0;
    }

    button {
      height: 2rem;
    }
  }
}
