//
// Accordions
// --------------------------------------------------

//Accordion Row
.accordion-content-row {
  .accordion-content {
    padding: 0;
  }
}

//Accordion Title
.accordion-title {

}

