// to fix :
// https://github.com/circlingthesun/angular-foundation-6/issues/83
html.is-reveal-open, html.is-reveal-open body {

  @include breakpoint(small only) {
    position: unset !important;
  }
}

.reveal-overlay {

  .reveal {
    padding: 0;
    border: none;
    box-shadow: 0 5px 25px rgba(0,0,0,0.25);
    outline: none;
    top: 5vh;
    overflow: visible;

    @include breakpoint(small only) {
      height: auto;
      min-height: 100px;
      width: calc(100% - 2rem);
      margin: 1rem;
      border-radius: $global-radius;
    }

    header {
       //background-color: $primary-base;
       //color: $white;
      color: $primary-base;
      border-bottom: solid 1px $light-gray;

      h2 {
        font-size: 1.2rem;
        text-align: center;
        text-transform: uppercase;
        color: $primary-base;
        padding: 1rem 0.5rem;
        margin-bottom: 0;
      }
    }

    .close-button {
      //color: $white;
      //font-size: 2rem;
      //top: 0;
      //right: 10px;
      position: absolute;
      width: 25px;
      height: 25px;
      font-size: 1.5rem;
      color: $gray-base;
      border-radius: 50px;
      box-shadow: 0 5px 25px rgba(0,0,0,0.25);
      top: -10px;
      right: -10px;
      background-color: $white;
      z-index: 100;
    }

    .content {
      padding: 1rem;

      .button {
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }


    .offer-details {

      header {
        background-color: $primary-base;
        color: $white;
        margin-bottom: 1rem;
        border-radius: $global-radius $global-radius 0 0;
        padding: 0;

        >.row {
          display: flex;
        }

        .prices {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          border-right: solid 1px rgba(255,255,255,0.35);

          .stat {
            font-family: $font-family-second;
          }

          p, span {
            font-size: 0.75rem;
            line-height: 1.2;
            margin-bottom: 0;
            opacity: 0.75;
          }

          tooltip {

            tip-cont {
              a {
                color: $white;
                opacity: 0.75;
                font-size: 0.8rem;
              }
            }

            tip {
              min-width: 200px;
              box-shadow: 0 8px 15px rgba(0,0,0,0.25);
              background-color: $gray-base;
              padding: 0.5rem;

              tip-tip {
              }

              tip-arrow {
                border-bottom-color: $gray-base;
              }
            }
          }
        }

        .product {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: 0.75rem;
          opacity: 0.75;
          text-align: right;

          ul {
            margin-bottom: 0;

            li {
              margin-bottom: 5px;
              line-height: 1.2;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .card-section {
        border-bottom: none;

        &.supplier {
          border-bottom: solid 1px darken($light-gray,10%);
          padding: 0 0 1rem 0 !important;
          margin-bottom: 1rem;
          margin-left: 1rem;
          margin-right: 1rem;

          img.supplier-image {
            width: auto;
            height: 200px;
            max-width: 200px;
            max-height: 50px;
            float: left;
            margin-right: 0.5rem;
          }

          .supplier-title {
            font-weight: bold;
            font-family: $body-font-family;
            color: $medium-gray;
            text-align: right;
            margin-bottom: 0.25rem;
          }

          .rating {
            float: right;

            .rating-link {
              margin-left: 0.35rem;
              font-size: 0.75rem;
              line-height: 22px;
            }

            .ngrateit {

              .ngrateit-star {
                background-size: 10px;
              }
            }
          }
        }

        p {
          font-size: 0.85rem;
          line-height: 1.2;
          color: $medium-gray;
          margin-bottom: 1em;

          &.is-action {
            color: $danger-base !important;
            text-align: center;
            padding-bottom: 0.75rem;
            border-bottom: solid 1px  darken($light-gray, 5%);

            span {
              color: $secondary-base;

              @include breakpoint(small only) {
                display: block;
              }
            }
          }
        }

        .info-link {
          font-size: 0.85rem;

          span {
            text-decoration: underline;
          }
        }

        &.sumary {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      footer {
        background-color: $light-gray;
        border-radius: 0 0 $global-radius $global-radius;

        .button-offer-order {
          background-color: $success-base !important;
          color: $white;

          &:hover {
            background-color: darken($success-base, 5%) !important;
            border-color: darken($success-base, 5%) !important;
          }
        }
      }

      .ratings-content {
        padding-top: 1rem;
        padding-bottom: 1rem;

        ul {

          li {
            border-bottom: solid 1px $light-gray;
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .offer-personal {
      max-width: 100%;
    }

    .user-signup {
      .button-signup {
        @extend .success;
        color: $white !important;

        &:hover {
          background-color: darken($success-base, 10%) !important;
        }
      }
    }
  }
}

