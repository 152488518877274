//
// Dividers
// --------------------------------------------------

/**
  -- Pipe Markup --

  <div class="pipe-divider">
    <div class="divider"></div>
  </div>

  <div class="pipe-divider">
    <div class="divider">Or</div>
  </div>
 */
.pipe-divider {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: $medium-gray-base-2;
    left: 50%;
    top: 0;
  }

  .divider:not(:empty) {
    position: absolute;
    color: $medium-gray-base;
    background-color: $white;
    padding: 0.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
  }
}


/**
  -- Dash Markup --

  <div class="dash-divider">
    <span class="divider"></span>
  </div>

  <div class="dash-divider">
    <span class="divider">Or</span>
  </div>
 */
.dash-divider {

  .divider:not(:empty) {

  }
}
