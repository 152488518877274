//
// UI Swiper
// --------------------------------------------------

ui-swiper {

  .swiper-container {
    background-color: $secondary-base;
    color: $white;

    .swiper-wrapper {

      .swiper-slide{
        background: none !important;
        background-color: transparent !important;

        .callout {
          background-color: transparent !important;
          color: $white !important;
          width: 100%;

          &.callout-news {
            text-align: left;
            padding-left: 1rem;
            padding-right: 6rem;

            @include breakpoint(small only) {
              padding-left: 1rem;
              padding-right: 1rem;
            }

            [data-ng-bind-html="alert.title"] {
              font-family: $font-family-second;
              font-size: 1rem;
            }

            [data-ng-bind-html="alert.content"] {
              font-size: 0.85rem;
              color: $white;
            }
          }
        }
      }
    }

    ui-swiper-prev,
    ui-swiper-next {

      .swiper-button-prev,
      .swiper-button-next {
        background-image: none !important;

        .fi {
          border: solid 1px $white;
          line-height: 50px;
          border-radius: 500px;
          padding: 4px;
          background-color: $primary-base;
          opacity: 0.5;
          transition: all linear 0.3s;

          @include breakpoint(small only) {
            display: none;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    ui-swiper-prev {
      position: absolute;
      top: 50%;
      right: 5rem;
    }
  }
}
