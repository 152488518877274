//
// Variables
// --------------------------------------------------

//Base Grid
$main-columns:          12;
$sidebar-columns:       3;

//== Color Palette
$primary-base:           #006c95 !default;
$secondary-base:         #4d4c4c !default;
$success-base:           #00bdab !default; //#00a293; old color
$info-base:              #5bc0de !default;
$warning-base:           #f59f42 !default;
$danger-base:            #cc4b37 !default;

$black-base:             #000    !default;
$white-base:             #fefefe !default;
$gray-base:              lighten($black-base, 30%) !default;
$lighter-gray-base:      lighten($black-base, 95%) !default;
$light-gray-base-bg:     lighten($black-base, 93.5%) !default;
$light-gray-base:        lighten($black-base, 93.5%) !default;
$light-gray-base-stroke:     lighten($black-base, 91%) !default;
$medium-gray-base-2:     lighten($black-base, 70%);
$medium-gray-base:       lighten($black-base, 46.7%) !default;
$dark-gray-base:         lighten($black-base, 20%) !default;

//== Typography
//** First Font
$font-name-first:            "MuseoSansRounded-700";
$font-family-first:          $font-name-first, sans-serif !default;
$first-font-size:            15px;

//** Second Font
$font-name-second:            "MuseoSansRounded-900";
$font-family-second:          $font-name-second, sans-serif !default;
$second-font-size:            15px;

//** Google Font
$font-name-google:            "Lato";
$font-family-google:          $font-name-google, sans-serif !default;
$google-font-size:            15px;

//** Icons Font
$font-name-icons:             "FontFMS";
$font-family-icons:           $font-name-icons !default;
$icons-font-size:             14px !default;
$icons-font-height:           1 !default;

//** Monospace Font
$font-family-monospace:       Menlo, Monaco, Consolas, "Courier New", monospace !default;
$monospace-font-size:         15px;

//** Text Font
$font-family-text:            $font-family-first;
$text-font-size:              15px;

//** Title Font
$font-family-title:           $font-family-first;
$title-font-size:              15px;



//== Iconography
$icon-font-path:              "../fonts/";

//== Fonts
$font-path:                   "../fonts/";

//== Images
$img-path:                    "../images/";
